
<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">
<div class="p-0 mt-0" style="width: 100%;height: 100%;">
    <app-header></app-header>
    <div class="main-block">
    <div class="d-flex justify-content-between wrap-block create-col">
        <div class="mt-2 create-btn">
            <button type="button" class="btn btn-secondary btn-sm new-btn" (click)="openAddMemberModal()"
                style="background-color: #5D3C6C;">
                Create Forms
            </button>
        </div>
        <div class="mt-2" style="display: flex; align-items: center; margin-right: 20px;" class="forms-search">
            <div style="margin-right: 20px;" class="form-search-sub">
                <mat-form-field style="width: 100%; margin-top: auto;">
                    <mat-select [(value)]="selectedType" style="margin-top: auto;" placeholder="User Type"
                        (selectionChange)="onSelectChange($event)" panelClass="custom-dropdown-panel"
                        disableOptionCentering>
                        <mat-option [value]="0">None</mat-option>
                        <mat-option *ngFor="let role of roleList?.roleType" [value]="role.id">{{role.role}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="search-form-field">
                <mat-form-field>
                    <input matInput (keyup)="filterEvent($event)" [(ngModel)]="searchKey"
                        placeholder="Type to Search" />
                </mat-form-field>
                <mat-icon class="searchIcon"  (click)="applyFilter()"  style="cursor: pointer;">search</mat-icon>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 table-container" style="margin-bottom: 60px">
        <table mat-table [dataSource]="dataSource" matSort class="sharepoint-table">

            <ng-container matColumnDef="title">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Title
                </th>
                <td mat-cell *matCellDef="let row" style="width: 20%;padding-left: 20px;padding-right: 20px;">{{ row.title }}</td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected User Type(s)
                </th>

                <!-- <td mat-cell *matCellDef="let row">{{ row.content }}</td> -->
                <td mat-cell *matCellDef="let row" style="width: 40%;padding-left: 20px;padding-right: 20px;">
                    <ng-container *ngFor="let role of row.roleId; let last = last">
                        {{ role.roleName }}
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="Vendor" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Vendor Group(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;">
                    <ng-container *ngFor="let role of row.vendorGroupList; let last = last">
                        <span *ngIf="row.vendorGroupList.length==0">N/A</span>
                        {{ role.vendorGroupName==''?'N/A': role.vendorGroupName }}
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="category" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Category/Sub Category(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;" >
                         
                            <div class="view-icon" style="cursor: pointer;"  matTooltip="View Category/Sub Category " (click)="openSubCategoryModel(row)" *ngIf="row.category.categoryList!=null"> <img src="assets/img/view-light.png"  /></div> 
                     </td>
            </ng-container>
            <!-- <ng-container matColumnDef="Category" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Category(s)/Sub Category(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;" >
                    <span >{{ row.category.parentCategory==null?'N/A':row.category.parentCategory}}</span><span *ngIf="row.category.parentCategoryId!=0 && row.category.subCategory!=null">/</span>
                         <ng-container *ngFor="let role of row.category.subCategory; let last = last">
                            <span > {{ role.subCategory ==null?'/N/A':role.subCategory }}</span>
                            <span *ngIf="!last">, </span>
                        </ng-container>
                </td>
            </ng-container> -->
            <!-- <ng-container matColumnDef="Category" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Category(s)/Sub Category(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;" >
                        
                         <ng-container *ngFor="let role of row.category.categoryList; let last = last">
                            <span > {{ role.parentCategory ==null?'/N/A':role.parentCategory }}</span>
                                    <span *ngIf="!last">,</span>
                                    <span *ngIf="last">/</span>
                                </ng-container>
                                <ng-container *ngFor="let roless of (row?.category?.categoryList ?? []);">
                                    <ng-container *ngFor="let roles of (roless?.subCategory ?? []); let last = last">    
                                      <span> {{roles?.subCategory +','}}</span>
                                      <span ></span>
                                    </ng-container>
                                  </ng-container>
                      
                </td>
            </ng-container> -->
            <ng-container matColumnDef="create_date">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef style="width: 14%;">
                    Date Created On
                </th>
                <td mat-cell *matCellDef="let row">
                    {{row.created_date ? (row.created_date | date:'dd/MM/yyyy') : 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let row" style="width:11%;">
                    <!-- <mat-icon class="mr-2" matTooltip="Edit" (click)="editAnnouncement(row)"
                        style="cursor: pointer;" class="edit-icon">edit</mat-icon> -->
                        <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="editAnnouncement(row)" >
                            <img src="assets/img/view.png" />
                        </div>
                    <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;"
                        (click)="deleteSharePoint(row.id)" class="delete-icon">delete</mat-icon> -->
                        <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
                        (click)="deleteSharePoint(row.id)">
                            <img src="assets/img/delete.png" >
                          </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="this.users?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
            <p>no records found </p>
        </div>


        <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [length]="totalItems"
            [(pageSize)]="pageSize" [(pageIndex)]="currentPage" (page)="onPaginatorClick($event)">
        </mat-paginator>


    </div>

</div>

    <div class="modal fade" id="newformCreate" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="staticBackdropLabel" data-keyboard="false" aria-hidden="true" style="margin-top: 50px;">

        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">{{typeofSharePoint}} Forms</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        <!-- <img src="assets/img/modal-close.png" /> -->
                        <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                    </button>
                </div>

                <div *ngIf="addAnnouncementForm">
                    <form [formGroup]="sharePointForm" (ngSubmit)="saveSharePoint()">
                        <div style="margin: 20px;">
                            <div class="form-group mb-3" style="margin-right: 10px;">
                            <label for="firstName">Heading <span
                                    style="color: red;">*</span></label>
                            <!-- <mat-form-field style="width: 100%;margin:0;"> -->
                                <input formControlName="title" class="form-control"
                                    [readonly]="typeofSharePoint == 'Edit' ?true: false" />
                                
                            <!-- </mat-form-field> -->
                            <div *ngIf="sharePointSubmit && memberControl.title.errors" class="ml-2">
                                <div *ngIf="memberControl.title.errors.required" class="text-danger">
                                    Title required
                                </div>
                                <div *ngIf="memberControl.title.errors.noWhiteSpace" class="text-danger">
                                    {{ memberControl.title.errors.noWhiteSpace }}
                                </div>
                            </div>
                        </div>

                            <div>
                                <label for="firstName">Select User Type <span
                                        style="color: red;">*</span></label><br>
                                <mat-checkbox *ngFor="let role of roleList?.roleType" color="primary"
                                    [disabled]="apiselectedRoles.includes(role.id)" style="
                                    margin-right: 15px;" [checked]="selectedRoles.includes(role.id)"
                                    (change)="toggleRoleSelection(role.id)">{{role.role}}</mat-checkbox>
                                <div *ngIf="isSelectedRoles" class="ml-2">
                                    <div class="text-danger">
                                        UserType required
                                    </div>
                                </div>
                            </div>


                            <!-- <label style="font-weight: 100;margin-top: 5px;" for="firstName">User Type <span
                                    style="color: red;">*</span></label><br>
                            <mat-radio-group [(ngModel)]="rolevalue">
                                <mat-checkbox *ngFor="let role of roleList?.roleType" style="margin-right: 15px;"
                                    [checked]="selectedRoles.includes(role.id)"
                                    (change)="toggleRoleSelection(role.id)">{{role.role}}</mat-checkbox>
                            </mat-radio-group>
                            <br /> -->
                            <label style="margin-top: 10px;" for="firstName">SharePoint Link
                                <span style="color: red;">*</span></label><br>
                            <textarea formControlName="html" class="content-container" placeholder="SharePoint Link"
                                [readonly]="typeofSharePoint == 'Edit' ?true: false"></textarea>
                            <div *ngIf="sharePointSubmit && memberControl.html.errors" class="ml-2">
                                <div *ngIf="memberControl.html.errors.required" class="text-danger">
                                    SharePoint required
                                </div>
                                <div *ngIf="memberControl.html.hasError('pattern')" class="text-danger">
                                    Please enter a valid link.
                                </div>
                            </div>
                            <br>
                            <label style="margin-top: 10px;" for="firstName">Notes
                            </label><br>
                            <textarea formControlName="notes" class="content-container" maxlength="150"
                                [readonly]="typeofSharePoint == 'Edit' ?true: false"
                                placeholder="Notes (100 characters)"></textarea>
                            <!-- <div *ngIf="sharePointSubmit && memberControl.notes.errors" class="ml-2">
                                <div *ngIf="memberControl.notes.errors.required" class="text-danger">
                                    notes required
                                </div>
                            </div> -->

                            <div class="form-group mb-3" style="margin-right: 10px;">
                                <label for="roleType">Catagory </label>
                                <select class="form-control" formControlName="catagory" id="roleType" placeholder="roleType" style="
                                                        padding-left: 15px;" (change)="oncatogorySelect($event)">
                                  <option value=0 >Select  Catogory</option>
                                  <!-- <option value="">Select State</option> -->
                                  <option *ngFor="let role of this.catagoryDropDown" value="{{role.categoryId}}" [disabled]="this.categoryMap.includes(role.categoryId)">{{role.categoryName}}</option>
                                </select>
                                
                              </div>
                              <div class="form-group mb-3" *ngIf="isPresent">
                                <label for="roleType">Sub Catagory <span style="color: red;" *ngIf="this.subcategoryMap.length==0"> *</span></label>
                                <!-- <select class="form-control" formControlName="subcatogorycatagory" id="roleType" placeholder="roleType" style="
                                                        padding-left: 15px;" (change)="onSubcatogorySelect($event)">
                                  <option value=0 >Select Sub Catogory</option>
                                 
                                  <option *ngFor="let role of  this.subCatagoryDropDown" value="{{role.categoryId}}" [disabled]="this.subcategoryMap.includes(role.categoryId)">{{role.categoryName}}</option>
                                  
                                </select> -->
                                <ng-select   class="custom-ng-select"
                                bindLabel="name"                
                                bindValue="id"
                                placeholder="Select item"         
                                formControlName="subcatogorycatagory"
                                [multiple]="true"              
                                [closeOnSelect]="false"
                                (change)="onSelectGroups($event,'sub')" >
                              <ng-option [value]="'all'" [disabled]="allhideSubAll "  *ngIf="this.subcategoryMap.length==0 && subCatagoryDropDown.length!=1"  >
                                All
                              </ng-option>
                               <ng-option *ngFor="let role of subCatagoryDropDown" 
                               [value]="role.categoryId" 
                               [disabled]="allhideSub || this.subcategoryMap.includes(role.categoryId)">
                               {{role.categoryName}}
                              </ng-option>
                            
                              </ng-select> 
                                
                              </div>
                              <div class="form-group mb-3" *ngIf="this.vendorMap.length!=this.groupDropDown.length" >
                                <label>Vendor Group</label>
                                <!-- <select class="form-control" id="groupId" formControlName="groupId">
                                    <option value=0 disabled selected>Select Group</option>
                                    <option *ngFor="let group of this.groupList" value="{{this.group.id}}" [disabled]="this.vendorMap.includes(group.id) && !this.isShow">{{ group.name }}</option>
                                  </select> -->
                             <ng-select  style="font-size: 1rem;"  class="custom-ng-select"
                              bindLabel="name"                
                              bindValue="id"
                              placeholder="Select item"         
                              formControlName="groupId"
                              [multiple]="true"              
                              [closeOnSelect]="false"
                              
                              (change)="onSelectGroups($event,'group')" >
                            <ng-option [value]="'all'" [disabled]="allhidegroupAll"  *ngIf="this.vendorMap.length==0 && groupList.length!=1  ">
                                All
                            </ng-option>
                             <ng-option *ngFor="let group of groupList" 
                             [value]="group.id" 
                             [disabled]="allhide ||  this.vendorMap.includes(group.id)">
                             {{ group.name }}
                            </ng-option>
                          
                            </ng-select>  

                                
                              </div>
                        </div>
                        
                        <div class="footer-modal">
                            <button [disabled]="!checkAllFieldsEntered()" class="sync-btn">
                                <span>Submit</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
</div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>
<div class="modal fade" id="SubModelShow" role="dialog" aria-labelledby="staticBackdropLabel"
     style="margin-top: 50px;">

    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Category/Subcategory Details</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <div class="mat-elevation-z8 table-container">
                <table mat-table [dataSource]="dataSources" matSort class="sharepoint-table">
                    <ng-container matColumnDef="Category">
                        <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let row" style="width: 30%;padding-right: 20px;">{{ row.parentCategory }}</td>
                    </ng-container>
                    <ng-container matColumnDef="SubCategory">
                        <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                            SubCategory
                        </th>
                        <td mat-cell *matCellDef="let row" style="width: 30%;padding-right: 20px;">
                            <span *ngFor="let row of row.subCategory; let last = last">
                               {{row.subCategory || 'N/A'}}
                         
                            <span *ngIf="!last">, </span> 
                        </span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                
                </table>
            </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-header></app-header>


<div class="container main">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="card reset-psd-card">
                <div class="card-body reset-psd-card-body">
                    <div class="reset-psd-header">
                        <div class="reset-psd-text">
                            Forms and Application
                        </div>
                    </div>
                    <div class="mt-4 reset-psd-form">
                        <label for="interval">User Type</label>

                        <div>
                            <mat-form-field appearance="fill">
                                <mat-select>
                                    <mat-option *ngFor="let role of roleList?.roleType"
                                        value="year">{{role.role}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <textarea class="content-container" placeholder="Enter Share Point link"></textarea>
                        </div>

                        <button class="sync-btn" (click)="saveBtn()">
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner size="default" color="#fff" type="square-loader" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->