import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { sharePointListsResponse } from '../shared/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { SharedalertService } from '../shared/services/sharedalert.service';

declare var $: any;

export interface UserData {
  id: Number,
  roleId: Number,
  title: String,
  content: String,
  created_date: String,
}

@Component({
  selector: 'app-sharepoint',
  templateUrl: './sharepoint.component.html',
  styleUrls: ['./sharepoint.component.css']
})
export class SharepointComponent implements OnInit {
  token: string;
  roleList: any;
  isShow:boolean;
  dataSource: MatTableDataSource<UserData>;
  title: any;
  selectedRoles: string[] = [];
  userRoles:any[]=[];
  sharePointLInkId: any;
  rolevalue: any;
  displayedColumns: string[] = [
    "title",
    "roles",
    "Vendor",
    "category",
    "create_date",
    "actions"
  ];
  displayedColumn:string[]=[
    "Category",
    "SubCategory"
  ]
  filter: string;
  shareLink: any;
  // @ViewChild(MatSort) sort: MatSort;
 notes:string;
 titles:string;
 sharePointLink:string;
 parentmap: any;
  // disableSorting() {
  //   this.sort.disabled = true;
  // }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  sharePointForm: FormGroup;
  users: UserData[];
  addAnnouncementForm: boolean;
  sharePointSubmit: boolean;
  isSelectedRoles: boolean;
  typeofSharePoint = "Create"
  apiselectedRoles: string[] = [];
  pageSize = 10;
  currentPage = 0;
  searchKey: any = '';
  selectedType: Number
  totalItems: any;
  catagoryDropDown: any[];
  subCatagoryDropDown:any[];
  isPresent: boolean=false;
  VendorGroupArray: any[];
  groupList: any;
  groupDropDown: any[];
  subCatagoryDropDowns: any;
  subcategoryDropDowns: any[];
  subcategoryMap: any[]=[];
  userTypeMap:any[]=[];
  vendorMap: any[]=[];
  disable: boolean=false;
  ismandate: boolean;
  categoryMap: any[]=[];
  Allvendormap: any;
  allhide: boolean;
  AllSubcategoryMap: any[]=[];
  allhideSub: boolean;
  allhideSubAll: boolean;
  allhidegroupAll: boolean;
  editObject: any;
  subcategoryMaps: any;
  dataSources: MatTableDataSource<any>;

  constructor(private spinnerService: NgxSpinnerService, private sharedService: SharedalertService, private memberService: MemberService, private formBuilder: FormBuilder) {
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      notes: ['', [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.getRoles();
    this.getCatogoryList();
    this.GetVendorGroupList();
    this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, 0);
    // this.checkAllFieldsEntered();
  }
  
  GetVendorGroupList(){
    // this.spinnerService.show(); 
    this.memberService.getVendorGroup(this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
         this.VendorGroupArray=data.vendorGroupList;
         this.VendorGroupArray=this.VendorGroupArray.filter(group=>group.isActive==1 &&group.memberCount!=0 ); 
         this.groupList= this.VendorGroupArray.map(group => ({
          id: group.groupId,
          name: group.name,
        }));

        console.log('groupList',this.VendorGroupArray);
        this.groupDropDown=this.VendorGroupArray.filter(group=>group.isActive==1 );  
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }
  openSubCategoryModel(row:any){
    if(row.category.categoryList!=null){
    this.dataSources = new MatTableDataSource(row.category.categoryList);
    
    // let subcategoryMap=row.category.categoryList.map((value)=>value.subCategory);
    // let subactegoryname=subcategoryMap.flat();
    // console.log('SubCategoryMAp',subactegoryname);
    // console.log(subactegoryname);
    // this.subcategoryNames=subactegoryname.join();

   $("#SubModelShow").modal("show");

    }


  }

  getCatogoryList(){
    this.memberService
    .getCatogoryList( this.token)
    .subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          this.catagoryDropDown=data.categoryLists;
          this.catagoryDropDown=this.catagoryDropDown.map(value=>({
            categoryName:value.categoryName,
            categoryId:parseInt(value.categoryId),
          })
          );
        
          this.subCatagoryDropDowns = data.categoryLists.map((value=>value.subCategoryList));
          
          this.subCatagoryDropDowns = data.categoryLists
          .map(value => value.subCategoryList) // Extract subCategoryList arrays
          .flat() // Flatten the array of arrays into a single array
          .map(value => ({
            categoryName: value.categoryName,
            categoryId: parseInt(value.categoryId),
            parentCategoryId: parseInt(value.parentCategoryId)
          })); 
          
        
        console.log('usb',this.subCatagoryDropDowns)

          // this.groupList= data.vendorGroupList.map(group => ({
          //   id: group.groupId,
          //   name: group.name,
          // }));
         console.log('data -------> ',this.catagoryDropDown);
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }
  // oncatogorySelect(event:any){
  //   this.disable=this.subcategoryMap.length==0?true:false;
  //   let subcatogoryList=  this.subCatagoryDropDown;
  //   this.isPresent=false;
  //   console.log('s',this.subCatagoryDropDowns);
  //   console.log('Event',(event.target as HTMLSelectElement).value);
  //   console.log('Subcatogory',this.subCatagoryDropDown);
  //   this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
  //   (x) => x.parentCategoryId == (event.target as HTMLSelectElement).value
  //   );
  //   subcatogoryList=this.subcategoryDropDowns;
  //   // subcatogoryList=this.subCatagoryDropDown;
    
  //   console.log('subcatogory',this.subCatagoryDropDown);
  //   this.isPresent=  this.subCatagoryDropDown.length==0?false:true;
  // }
  oncatogorySelect(event:any){
    this.subcategoryMaps=[];
    let subcatogoryList=  this.subCatagoryDropDown;
    this.isPresent=false;
    console.log('s',this.subCatagoryDropDowns);
    console.log('Event',(event.target as HTMLSelectElement).value);
    console.log('Subcatogory',this.subCatagoryDropDown);
    this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
    (x) => x.parentCategoryId == (event.target as HTMLSelectElement).value
    );
    if(this.subCatagoryDropDown.length==0 ){
      console.log('Map',this.parentmap);
      console.log('Value',(event.target as HTMLSelectElement).value);
      let n=parseInt((event.target as HTMLSelectElement).value);
      console.log(n);
      if( this.parentmap.includes(n)){
         this.categoryMap.push(n);
         this.sharePointForm.controls['catagory'].setValue(0);
         return;
      }
      else{
       this.categoryMap.push()
      }
     }
    this.isPresent=  this.subCatagoryDropDown.length==0?false:true;
    if( this.isShow==false){
    console.log( 'Parent',this.subCatagoryDropDown[0].parentCategoryId);
    console.log(this.editObject);
    let m = this.editObject.category.categoryList
    .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==this.subCatagoryDropDown[0].parentCategoryId).map((value)=>value);
    console.log('M',m);
    console.log('SubCategoryMapLength',this.subCatagoryDropDown);
    console.log('SubMap',this.subcategoryMap);
    if(m.length==this.subCatagoryDropDown.length){
    this.categoryMap.push(this.subCatagoryDropDown[0].parentCategoryId);
    this.sharePointForm.controls['catagory'].setValue(0);
    this.isPresent=false;
     this.sharePointForm.controls['subcatogorycatagory'].setValue([]);
      
    }
  }
    console.log('CategoryMap',this.categoryMap);
    subcatogoryList=this.subcategoryDropDowns;
    console.log('k',this.subCatagoryDropDown);
    // if(this.subCatagoryDropDown.length>=2){
    // this.SubCategoryWithAll = [{ categoryId: 'all', categoryName: 'All' }, ...this.subCatagoryDropDown];
    // }
    // else{
    //   this.SubCategoryWithAll=this.subCatagoryDropDown;
    // }
   
  // console.log('subCatagoryDropDownLength',this.subCatagoryDropDown);
  // let parentmap=this.editObject.category.categoryList.map((value)=>value.parentCategoryId);
  // console.log('parentMap',parentmap);
  // if(this.subcategoryMaps.length==this.subCatagoryDropDown.length){
    
  //    this.categoryMap=this.editObject.category.categoryList
  //    .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==this.editObject.category.currentCategoryId).map((value)=>value.parentCategoryId);
  //    console.log('CategoryMap',this.categoryMap);
  //    this.announcementForm.controls['catagory'].setValue(0);
  // }
    

    // subcatogoryList=this.subCatagoryDropDown;
    console.log('subcatogory',this.subCatagoryDropDown);
   
  }
  onSubcatogorySelect(Event:any){

  }
  getsharePointLists(pageNo, pageSize, searchKey, roleType) {
    // this.spinnerService.show();
    let params = {
      "searchTitle": searchKey,
      "roleId": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    }
    this.memberService.getsharePointLists(params, this.token).subscribe(
      (data: { totalCount: Number;filteredCount:Number;sharePointLists: sharePointListsResponse[] }) => {
        console.log(data);
        // setTimeout(() => {
        //   this.spinnerService.hide();
        // }, 800)
        
        let userData = this.getMemberListArray(data.sharePointLists);
        console.log(userData)
        this.users = userData;
        console.log('Userdata',this.users);
        this.dataSource = new MatTableDataSource(userData);
        // this.dataSource.paginator = this.paginator;
        this.totalItems = data.filteredCount
        // this.dataSource.sort = this.sort;
        this.spinnerService.hide();
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
        else {
          this.spinnerService.hide();
        }
      }
    );
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };


  getMemberListArray(sharePointLists: sharePointListsResponse[]) {
    return sharePointLists.map(member => ({
      id: member.sharePointId,
      roleId: member.roleId,
      title: member.sharePointTitle,
      content: member.sharePointLink,
      created_date: this.GetFormattedDate(member.createdDate),
      notes:member.notes,
      categoryId:member.parentCategoryId,
      categoryName:member.parentCategory,
      subcategoryName :member.subCategory,
      SubcategoryId:member.subCategoryId,
      vendorId:member.vendorGroupId,
      vendorGroupList:member.vendorGroupList,
      category:member.category
    }));
  }

  getRoles() {
    if (this.roleList) {
      return this.roleList;
    }

    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }



  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }


  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getsharePointLists(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }



  toggleRoleSelection(role: string) {
    console.log('',role);
    if(! this.userTypeMap.includes(role)){
      this.userRoles.push(role);
    }
    console.log(this.userRoles);
    const index = this.selectedRoles.indexOf(role);
    if (index === -1) {
      // Role is not in the array, add it
      this.selectedRoles.push(role);
    } else {
      // Role is already in the array, remove it
      this.selectedRoles.splice(index, 1);
    }
   
    if (this.selectedRoles.length > 0) {
      this.isSelectedRoles = false
    }
    else {
      this.isSelectedRoles = true
    }
  }

  hide() {
    this.sharePointSubmit = false;
    this.isSelectedRoles = false;
    this.sharePointForm.reset();
    this.selectedRoles = [];
    this.categoryMap=[];
    this.disable=false;
    $("#newformCreate").modal("hide");
    $("#SubModelShow").modal("hide");
    this.isPresent=false;
  }

  openAddMemberModal() {
    this.vendorMap=[];
    this.subcategoryMap=[];
    this.isShow=true;
    this.isPresent=false;
    $("#newformCreate").modal("show");
    this.typeofSharePoint = "Create"
    this.addAnnouncementForm = true;
    this.sharePointForm.reset();
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      html: ['', [Validators.required, Validators.pattern(/^(http:\/\/|https:\/\/).+$/), WhiteSpaceValidator]],
      notes: ['', [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([]),
      groupId:[[],[]],
      catagory:[0,[]],
      subcatogorycatagory:[[],[]]
    });
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.sharePointLInkId = 0
  }


  editAnnouncement(row) {
    this.editObject=row;
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      html: ['', [Validators.required,Validators.pattern(/^(http:\/\/|https:\/\/).+$/)]],
      notes: [row.notes, [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([]),
      groupId:[[],[]],
      catagory:[row.category.currentCategoryId,[]],
      subcatogorycatagory:[[],[]]
    });
    this.categoryMap=[];
    this.subcategoryMap=[];
    this.subCatagoryDropDown=[];
    this.disable=false;
     this. userTypeMap=row.roleId;
     this.userTypeMap=this.userTypeMap.map(value=>value.roleId);
     this.isShow=false;
     this.allhideSub=false;
  //    if(row.category.parentCategoryId!=0){
  //     this.subcategoryMap=row.category.subCategory.map((x)=>x.subCategoryId);
  //     console.log('SubCategory',this.subcategoryMap);
  //     console.log('Disable',this.disable);

  //    //  subcategoryMap.'map((value) => this.subCatagoryDropDown.find((x) => x.id === value) || null).filter((x)=>x!=null);
  //    this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
  //     (x) => x.parentCategoryId == row.category.parentCategoryId
  //     );
  //     console.log('sub',this.subCatagoryDropDown);
      
  //     // this.subCatagoryDropDown = this.subCatagoryDropDown
  //     // .filter((x) => !subcategoryMap.includes(x.categoryId));
  //     // if(this.subCatagoryDropDown.length==0){
  //     //  this.catagoryDropDown=this.catagoryDropDown.filter((x)=>x.categoryId!=row.categoryId);
  //     // }


   
  //  }
  if(row.category.categoryList!=null){
  if(row.category.categoryList.currentCategoryId!=0||row.category.categoryList.currentCategoryId==undefined){
    this.subcategoryMap=row.category.categoryList
    .flatMap(category => category.subCategory)  
    .map(value => value.subCategoryId);   
    console.log('SubCategory',this.subcategoryMap);
   //  subcategoryMap.'map((value) => this.subCatagoryDropDown.find((x) => x.id === value) || null).filter((x)=>x!=null);
   this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
    (x) => x.parentCategoryId == row.category.currentCategoryId
    );
    console.log('sub',this.subCatagoryDropDown);
    
    
   //  this.subCatagoryDropDown = this.subCatagoryDropDown
   //  .filter((x) => !subcategoryMap.includes(x.categoryId));
   //  if(this.subCatagoryDropDown.length==0){
   //   this.catagoryDropDown=this.catagoryDropDown.filter((x)=>x.categoryId!=row.categoryId);
   //  }


    
 }
   this.isPresent=this.subCatagoryDropDown.length==0?false:true;
   console.log('SubCategoryMapLength',this.subcategoryMap.length);
   console.log('subCatagoryDropDownLength',this.subCatagoryDropDown.length);
  //  if(this.subcategoryMap.length==this.subCatagoryDropDown.length){
  //   this.categoryMap.push(row.category.parentCategoryId);
  //   this.disable=true;
  //   this.sharePointForm.controls['catagory'].setValue(0);
  //   this.isPresent=false;

  //  }
  //  else{
  //   this.categoryMap=[];
  //  }
  this.subcategoryMaps = row.category.categoryList
  .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==row.category.currentCategoryId).map((value)=>value);
  console.log('SubCategoryMapLength',this.subcategoryMap);
  console.log('subCatagoryDropDownLength',this.subCatagoryDropDown);
  this.parentmap=row.category.categoryList.map((value)=>value.parentCategoryId);
  console.log('parentMap',this.parentmap);
  if(this.subcategoryMaps.length==this.subCatagoryDropDown.length){
    
     this.categoryMap=row.category.categoryList
     .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==row.category.currentCategoryId).map((value)=>value.parentCategoryId);
     console.log('CategoryMap',this.categoryMap);
     this.sharePointForm.controls['catagory'].setValue(0);
     this.isPresent=false;
     this.sharePointForm.controls['subcatogorycatagory'].setValue([]);
  }
  else{
    this.categoryMap=[];
  }
  
}
   this.vendorMap=row.vendorGroupList.map((value)=>value.vendorGroupId);
   console.log(this.vendorMap);
   if(this.vendorMap.length==this.groupDropDown.length){
    this.sharePointForm.controls['groupId'].setValue([]);
    

   }
  //  this.groupList = this.groupList.filter((item) => !vendorMap.includes(item.id));
   console.log('Vector',this.groupList);
    console.log('row',row);
   
    console.log('Vlaue',this.sharePointForm.value.subcatogorycatagory);
    this.addAnnouncementForm = true
    this.typeofSharePoint = "Edit"
    console.log(row)
    this.selectedRoles = []
    this.apiselectedRoles = []
    if(this.vendorMap.length==this.groupDropDown.length){
      this.sharePointForm.controls['groupId'].setValue([]);
      
  
     }
    $("#newformCreate").modal("show");
    this.sharePointForm.controls['title'].setValue(row.title);
    this.sharePointForm.controls['html'].setValue(row.content);
    this.title = row.title
    this.shareLink = row.content
    this.sharePointLInkId = row.id
    this.notes=row.notes;
    this.titles=row.title,
    this.sharePointLink=row.sharePointLink
    row.roleId.forEach(element => {
      this.selectedRoles.push(element.roleId)
      this.apiselectedRoles.push(element.roleId)

    });
  }

  deleteSharePoint(id) {
    Swal.fire({
      title: "Delete Forms and Application",
      text: "Are you sure?  Do you want to delete Forms and Application?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        let params = {
          "sharepointLinkId": id
        }
        this.spinnerService.show();
        this.memberService
          .deleteSharePoint(params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                this.sharedService.successAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.spinnerService.hide();
                this.sharedService.errorAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    })

  }

  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    this.paginator.pageIndex = 0;
    // You can perform additional actions or logic based on the selected value
    this.getsharePointLists(1, this.pageSize, this.searchKey, this.selectedType);

  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }
  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchKey = filterValue
    this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)

    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log("filt", this.dataSource.filter)
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }


  get memberControl() {
    return this.sharePointForm.controls;
  }
  onSelectGroups(groups: any[],select:string ) {
    if(groups.length!=0 &&  select=='sub' && !groups.includes('all') ){
    this.allhideSubAll=true;
    return;
    }
    if(groups.length!=0 &&  select=='group' && !groups.includes('all') ){
      this.allhidegroupAll=true;
      return;
      }
    this.allhideSubAll=false;
    this.allhidegroupAll=false;
    if (groups.includes('all') && select=='group') {
      let map=this.groupList.filter(value=>!this.vendorMap.includes(value.id)).map((value)=>value.id);
      this.allhide=true;
      console.log('Map',map);
      this.Allvendormap=map;
      // If "All" is selected, do nothing and prevent further actions
      return;
    }
    else if(groups.includes('all') && select=='sub'){
      let map=this.subCatagoryDropDown.filter(value=>!this.subcategoryMap.includes(value.categoryId)).map((value)=>value.categoryId);
      this.allhideSub=true;
      this.AllSubcategoryMap=map;
      console.log('map sub ',map);
    }
    else{
 
      this.allhide=false;
      this.allhideSub=false;
      console.log('Mpa', this.AllSubcategoryMap)
      

    }
   
    // Your normal selection logic for individual items
    console.log('Selected Groups:', groups);
  }
  checkAllFieldsEntered(): boolean {
    return Object.keys(this.sharePointForm.controls).every(controlName => {
      if (controlName === 'notes') {
        // Skip validation for the "notes" field
        return true;
      }
      else if(controlName=='catagory' && !this.isPresent ){
        return true;
      }
      else if(controlName=='subcatogorycatagory'  && this.isPresent){
        if(this.subcategoryMap.length!=0){
          return true;
        }
        const control = this.sharePointForm.get(controlName);
       
        // if(control.value==0 && this.disable){
        //   return true;
        // }
         if(control.value==0){
          return false;
        }
        return true;
        }
       
      else if(controlName=='groupId'){
        return true;
      }
      const control = this.sharePointForm.get(controlName);
      return control.value !== '' && control.value !== null;
    });
  }

  // checkAllFieldsEntered(): boolean {
  //   let allValid = true;
  //   Object.keys(this.sharePointForm.controls).every(key => {
  //     // Skip Only For Update Module 
  //     if (
  //       key === "groupId" || 
  //       key === "subcatogorycatagory" || 
  //       key === "notes" || 
  //       key === "catagory"
  //     ) {
  //       return; // Skip this iteration
  //     }
  //       //  if(key=="subCategoryId" && this.isPresent){
  //       //   const control = this.sharePointForm.get(key);
  //       //   return control && control.value !== 0 && control.value !== null;
  //       // }
  //     const control = this.sharePointForm.get(key);
  //     if( control && control.value !== 0 && control.value !== null){
  //       allValid = false; 
  //     }
    
  //   });
  //   return allValid; 
    
  // }

  saveSharePoint() {
    this.sharePointSubmit = true;
    console.log(this.sharePointForm)
    if (this.selectedRoles.length === 0 || this.sharePointForm.invalid) {
      this.isSelectedRoles = true;

      if (this.selectedRoles.length > 0) {
        this.isSelectedRoles = false;
        return;
      }

      if (this.sharePointForm.invalid) {
        return;
      }

      return; // Return if either condition is true
    }
    this.sharePointSubmit = false;
    
    // console.log('Params',params);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();

    if (this.sharePointLInkId == 0) {
      let subcategoryIdArray=[];
      if(this.sharePointForm.value.subcatogorycatagory!=''){
      subcategoryIdArray.push(parseInt(this.sharePointForm.value.subcatogorycatagory));
      }
      let vendorgroupIdArray=[];
     if( this.sharePointForm.value.groupId!=''){
      vendorgroupIdArray.push(parseInt(this.sharePointForm.value.groupId))
     }
     let params = {
      "title": this.sharePointForm.value.title,
      "userTypeId": this.userRoles,
      "link": this.sharePointForm.value.html,
      "notes": this.sharePointForm.value.notes,
      "parentCategoryId":this.sharePointForm.value.catagory,
      "subCategoryId":this.allhideSub?this.AllSubcategoryMap:this.sharePointForm.value.subcatogorycatagory,
      "vendorgroupId":this.allhide?this.Allvendormap:this.sharePointForm.value.groupId

    }
    console.log('params',params);

      this.memberService
        .PostSendSharePoints(params, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Created Successfully",
                showCloseButton: true,
                allowOutsideClick: false
              })
                $("#newformCreate").modal("hide");
                this.sharePointSubmit = false;
                this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, 0);
                
             
              return;
            }
            else {
              this.errorMessage()
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
    else {
    //   let subcategoryIdArray=[];
    //   if(this.sharePointForm.value.subcatogorycatagory!=''){
    //   subcategoryIdArray.push(parseInt(this.sharePointForm.value.subcatogorycatagory));
    //   }
    //   let vendorgroupIdArray=[];
    //  if( this.sharePointForm.value.groupId!=''){
    //   vendorgroupIdArray.push(parseInt(this.sharePointForm.value.groupId))
    //  }
     

      //vendorgroupIdArray.push(1);
      let param = {
        "title": this.titles,
        "userTypeId": this.userRoles,
        "link": this.sharePointForm.value.html,
        "sharePointLInkId": this.sharePointLInkId,
        "notes": this.notes,
        "parentCategoryId":this.sharePointForm.value.catagory,
       "subCategoryId":this.allhideSub?this.AllSubcategoryMap:this.sharePointForm.value.subcatogorycatagory,
      "vendorgroupId":this.allhide?this.Allvendormap:this.sharePointForm.value.groupId
      }
      console.log(param);
      // return;
      this.memberService
        .updateSharePoints(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Forms and application sent to the selected group successfully",
                showCloseButton: true,
                allowOutsideClick: false
              })
              $("#newformCreate").modal("hide");
              this.getsharePointLists(1, this.pageSize, this.searchKey, 0);
              this.spinnerService.hide();
              return;
            }
            else{
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "warning",
                title: data.statusMessage,
                showCloseButton: true,
                allowOutsideClick: false
               })
              //  .then(() => {
              //   // $("#newformCreate").modal("hide");
              //   this.ngOnInit()
              // });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
  }

  errorMessage() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Something went wrong",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }

}
