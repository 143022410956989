import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Workbook } from 'exceljs';
import { MembersComponent } from '../members/members.component';

import * as XLSX from 'xlsx';
type AOA = any[][];
// Local Imports
import { MemberService } from "../shared/services/member.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";
import { FormControl, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from "../shared/services/file.service";

declare var $: any;

export interface RequestMemberList {
  memberId: Number;
  memberName: string;
  email: string;
  yetToConnectMemberList: []
}

export interface MemberConnectionList {
  memberName: string;
  date: Number;
}

@Component({
  selector: 'app-vendorconnect',
  templateUrl: './vendorconnect.component.html',
  styleUrls: ['./vendorconnect.component.css']
})
export class VendorconnectComponent implements OnInit {

   showModal = false;
    private dynamicDropdownOptions = [
      'Regular',
      'Privileged',
      'Most Privileged',
      'Guest',
      'Admin'
    ];
    response = {
      "vendorList": [
        {
          "firstName": "ns bulk",
          "lastName": "sn ",
          "email": "dnxVendortest_Bulk3@yopmail.com",
          "groupId": 1,
          "statusMessage": "Vendor inserted successfully"
        },
        {
          "firstName": "ss",
          "lastName": "Dnxbulk insert",
          "email": "dnxVendortest_BulkT4@yopmail.com",
          "groupId": 2,
          "statusMessage": "Vendor inserted successfully"
        },
        {
          "firstName": "invalid email",
          "lastName": "sn ",
          "email": "dnxVendortest_BulkT5",
          "groupId": 1,
          "statusMessage": "The email invalid."
        },
        {
          "firstName": "duplicate mail",
          "lastName": "Dnxbulk insert",
          "email": "dnxVendortest_BulkT3@yopmail.com",
          "groupId": 2,
          "statusMessage": "The email address already exists."
        }
      ],
      "statusCode": 200,
      "statusMessage": "Vendor inserted successfully"
    };

    approveLoading: boolean;
    revokeLoading: boolean;
    serviceRequest: boolean;
    services: MemberConnectionList[];
    searchtext: "";
    token:string;
    PageSize:number=10;
    pageNo:number=1;

    organizationName:string;
    roleTypes = [];
    users: RequestMemberList[];
    currentUser: RequestMemberList;
    selectedmemberId: Number;
    @ViewChild(MembersComponent) memberComponent!: MembersComponent;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    tempsearchdata: any;
    ismemberconnect: boolean;
    teamInitial: any;
    filter: string;
    isMapped:any[];
    ismemberconnectlist: boolean = true;
    yetToConnectMemberList: any;
    data: any[] = [];
    roleList: any;
    pageSize = 10;
    currentPage = 0;
    displayedColumns: string[] = [];
    dataSource: any;
    displayedColumn: string[] = ['First Name', 'Last Name',  'Email', 'Vendor Group',"Upload Status"];
    selectAll: boolean = false;
    selection: MatTableDataSource<any>;
    isShowValidate: boolean = false;
    totalItems: number;
    groupList: any;
    isShow:boolean=true;
   dataSources: MatTableDataSource<unknown>;
  
   @Output() hideParent = new EventEmitter<void>();
  vendorListArray: any[]=[];

    public onFileChange(event) {
      console.log("call")
      const file = event.target.files[0];
      console.log(file)
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const binaryData = e.target.result;
          const workbook = XLSX.read(binaryData, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
          const worksheet = workbook.Sheets[sheetName];
          this.data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          event.target.value = '';
          console.log('WorkSheet',worksheet);
          this.isShow=true;;
          this.validateAndFilterData();
        };
        reader.readAsBinaryString(file);
      }
    }
  
    // validateAndFilterData(): void {
    //   // Filter out rows with any empty values
    //   let filteredData = this.data.filter(item =>
    //     Object.values(item).some(value => value !== '')
    //   );
    //   console.log(filteredData);
  
    //   // Check if all required fields are present
    //   const hasRequiredFields = filteredData.every(row =>
    //     ['First Name*', 'Last Name*', 'Title*', 'Organisation*', 'Email*', 'Phone No*', 'User Type*'].every(field =>
    //       row.hasOwnProperty(field) && row[field] !== ''
    //     )
    //   );
  
    //   if (!hasRequiredFields) {
    //     this.errorMessage();
    //     return;
    //   }
  
    //   // Validate phone numbers and emails and add 'isValid' property to each row
    //   filteredData.forEach(row => {
    //     const isPhoneValid = /^\d{10}$/.test(row['Phone No*']);
    //     const isEmailValid = /\S+@\S+\.\S+/.test(row['Email*']);
  
    //     // Add 'isValid' property only if the condition is satisfied
    //     if (!isPhoneValid || !isEmailValid) {
    //       row['Message'] = false;
    //     } else {
    //       row['Message'] = true;
    //     }
    //   });
  
    //   function isValuePresent(array: any[], property: string, value: any): boolean {
    //     return array.some(obj => Object.values(obj).includes(value));
    //   }
    //   const propertyName = 'Message';
    //   const targetValue = false;
  
    //   const isPresent = isValuePresent(filteredData, propertyName, targetValue);
    //   if (isPresent) {
    //     this.isShowValidate = true
    //   }
    //   else {
    //     filteredData = filteredData.map(({ Message, ...rest }) => rest);
    //   }
    //   // Set up table display
    //   this.displayedColumns = Object.keys(filteredData[0] || {});
    //   this.dataSource = new MatTableDataSource(filteredData);
    //   // this.dataSource = filteredData;
    // }
   

    // Emit event to trigger parent modal hide

  
    validateAndFilterData(): void {
      // const isValidPhone = (phone: string) => /^\d{10}$/.test(phone);
      const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
      // console.log('data',this.data);
  
      // // Filter out rows with any empty values
      let filteredData = this.data.filter(item =>
        Object.values(item).some(value => value !== '')
      );
      console.log('Filtered Data',filteredData);
      if(filteredData.length==0){
        this.errorMessage();
        return;
      }
      // const EveryFieldsEntered=filteredData.filter((value)=> console.log(value));
      // console.group(EveryFieldsEntered);
     
      // // Check if all required fields are present
    //   const hasRequiredFields = filteredData.every(row =>
    //     ['First Name *', 'Last Name *', 'Email *', 'Vendor Group *'].every(field =>
    //         row.hasOwnProperty(field) && row[field].toString().trim() !== '' // Check for non-empty values
    //     )
    // );
    // console.log('Is True',hasRequiredFields);
      //  console.log('Required Fields',hasRequiredFields)
      //  if(!this.areAllFieldsFilled(filteredData)){
      //    this.errorMessage();
      //     return;
      //  }
      // if (hasRequiredFields) {
      //   this.errorMessage();
      //   return;
      // }
    const requiredKeys = ['First Name *', 'Last Name *', 'Email *', 'Vendor Group *'];
    const isValidData = filteredData.every((row, index) => {
    const rowKeys = Object.keys(row);
  
      // Check for missing keys
      if (rowKeys.length !== requiredKeys.length) {
          Swal.fire({
              icon: "warning",
              title: `Invalid Excel Format (Row ${index + 1})`,
              text: "The uploaded Excel file has missing or extra columns. Please ensure it includes only the following columns: 'First Name *', 'Last Name *', 'Email *', and 'Vendor Group *'.",
              allowOutsideClick: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "OK"
          });
          return false; // Stop further validation
      }
  
      // Check for empty fields
      const emptyField = requiredKeys.find(key => !row[key] || row[key].toString().trim() === '');
      if (emptyField) {
          Swal.fire({
              icon: "warning",
              title: `Invalid Data (Row ${index + 1})`,
              text: `The field '${emptyField}' is empty. Please fill in all required fields.`,
              allowOutsideClick: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "OK"
          });
          return false; // Stop further validation
      }
  
      // If all validations pass
      return true;
  });
  if(!isValidData){
    return;
  }
  
  
      // // Validate phone numbers and emails and add 'isValid' property to each row
      filteredData.forEach(row => {
        row['Message'] = isValidEmail(row['Email *']);
      });
  
      // // Check if 'Message' property has a 'false' value
      const isPresent = filteredData.some(row => row['Message'] === false);
  
      this.isShowValidate = isPresent;
  
      // // If 'Message' is 'false', remove the 'Message' property from each row
      if (!isPresent) {
        filteredData = filteredData.map(({ Message, ...rest }) => rest);
      }
  
      // Set up table display
      this.displayedColumns = Object.keys(filteredData[0] || {});
      this.dataSource = new MatTableDataSource(filteredData);
      this.totalItems = filteredData.length
      console.log('Data Source ',this.dataSource);
    }
  
  
    shouldShowColumn(column: string): boolean {
      // Replace this condition with your actual logic
      // For example, hide the 'isValid' column
      return column !== 'Error';
    }
  // not used 
  
    areAllFieldsFilled(data) {
      let allFieldsFilled = true; // Assume all fields are filled
  
      Object.keys(data).forEach(key => {
          if (key.includes('*')) { // Check if the key is a required field
              const value = data[key];
              if (!value || value.toString().trim() === '') { // Check if the value is empty
                  allFieldsFilled = false; // Mark as not filled
              }
          }
      });
  
      return allFieldsFilled;
  }
    onCheckboxChange(row: any): void {
      console.log('Checkbox change for row:', row);
    }
  
    onPageChange(event: any): void {
      this.currentPage = event.pageIndex;
    }
  
  
    constructor(private memberService: MemberService, private spinnerService: NgxSpinnerService, private router: Router, private matdialog: MatDialog, private fileService: FileService) {
      // this.spinnerService.show();
    }
    form = new FormGroup({
      searchtext: new FormControl()
    });
  
  
    ngOnInit() {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      this.token = "Bearer" + " " + user.jwtToken;
      this.organizationName=user.organaisationName;
      console.log("lokgge");
      this.GetVendorGroupList();
      
      
    }
    ngAfterViewInit(): void {
      // Ensure the MemberComponent is initialized before calling its methods
      if (this.memberComponent) {
        this.memberComponent.ngOnInit(); // Call the method in MemberComponent
      }
    }
    
     GetVendorGroupList(){
        this.spinnerService.show();
        this.memberService.getVendorGroup(this.token).subscribe(
          (data: any) => {
            // this.ishubspotLoading = false;
            if (data.statusCode == 200) {
               this.groupList=data.vendorGroupList;
               this.roleTypes=data.vendorGroupList.filter((value)=>value.isActive==1).map((value)=>value.name);
               console.log('RoleType',this.roleTypes);
            }
            else {
              // this.isLoading = false;
              Swal.fire({
                title: data.statusMessage,
                icon: "warning",
                showCloseButton: true,
                allowOutsideClick: false
              });
              this.spinnerService.hide();
    
            }
          },
          err => {
            if (err.status === 401) {
              this.spinnerService.hide();
              this.unauthorizedException();
            }
          }
        );
      }
    downloadExcels(){
      this.createExcelFile();
    }
  
  
  public async createExcelFile() {
      
    // Create a new workbook and add worksheets
    const workbook = new Workbook();
    const dataSheet = workbook.addWorksheet('Data');
    // Data Sheet For Creating an Excel 
    const optionsSheet = workbook.addWorksheet('Options');
    // Define dropdown options(Mapping According To the Admin subscription Usertpe )
    const dynamicDropdownOptions = this.roleTypes.map((value=>value));
    console.log('Dynamic ',dynamicDropdownOptions);
    // Add header row to the data sheet
    // --------------->Heading -----------------<
    //dataSheet.addRow(['First Name', 'Last Name', 'Title', 'Organization', 'Email', 'Phone No','User Type']);
    // Add data rows to the data sheet
    //---------------->Define Empty Row--------------->
    //dataSheet.addRow([ '', '', '', '','','','']);
  
    // Rich Text Foematting 
    dataSheet.getCell('A1').value = {
    'richText': [
      {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'First Name'},
      {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
    ]
    }
    dataSheet.getCell('B1').value = {
      'richText': [
        {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Last Name'},
        {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
      ]
      }
      
      dataSheet.getCell('C1').value = {
            'richText': [
              {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Email'},
              {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
            ]
            }
            // dataSheet.getCell('D1').value = {
            //   'richText': [
            //     {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Phone No'},
            //     {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
            //   ]
            //   }
      dataSheet.getCell('D1').value = {
                'richText': [
                  {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Vendor Group'},
                  {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
                ]
                }
   
  
  const columnsToValidate = ['A', 'B', 'C', 'D'];
  const rowStart = 2; // Starting row for validation
    const rowEnd = 10000; // Ending row for validation
    // const nonEmptyList = ['Not empty'];
  
    for (let col of columnsToValidate) {
      for (let i = rowStart; i <= rowEnd; i++) { // Apply validation to rows 2 to 100
        const cellAddress = `${col}${i}`;
      
     if(col=='D'){
          dynamicDropdownOptions.forEach((option, index) => {
      
            optionsSheet.getCell(`A${index + 1}`).value = option;
          });
          
          // Define the range for dropdown options  ()
          const optionsRange = `Options!$A$1:$A$${dynamicDropdownOptions.length}`;
          
          
            dataSheet.getCell(cellAddress).dataValidation = {
                type: 'list',
                formulae: [optionsRange],
                showErrorMessage: true,
                allowBlank: true
            };
        
        }
       
        else if(col=='A'){
          dataSheet.getCell(cellAddress).dataValidation = {
            type: 'textLength',
            operator: 'greaterThanOrEqual',
            showErrorMessage: true,
            allowBlank: false,
            formulae: [3],
            errorStyle: 'error',
            error: 'First Name Should Be More Than 3 Char '
            }
  
        }
  
        
        else {
        dataSheet.getCell(cellAddress).dataValidation = {
          type: 'textLength',
          operator: 'greaterThanOrEqual',
          showErrorMessage: true,
          allowBlank: false,
          formulae: [2],
          errorStyle: 'error',
          error: 'Fields Should be More Than One Char'
  
      };
    }
      }
    }
  
    dataSheet.getRow(1).eachCell({ includeEmpty: true }, (cell,colnumber) => {
    dataSheet.getColumn(colnumber).width = 20;
     
  });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'VendorData.xlsx';
    document.body.appendChild(a);
    a.click();
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    }
  
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
   //Bootstrap Modal Close event
    hide() {
      $("#memberReviewModel").modal("hide");
      this.showModal = false;
      this.isShow=false;
      $("#newVendorCreates").modal("hide");
    }
    downloadExcel() {
      this.fileService.getFile().subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'format.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  
    search(value: string, row: any) {
    
      let filter = value.toLowerCase();
      return row.yetToConnectMemberList.filter
        ((option) => option.memberName.startsWith(filter)
        );
    }
  
    sendEmail() {
      // this.hideParent.emit(); 
      this.spinnerService.show();
      console.log(this.dataSource.data);
       let userData = this.getMemberListArray(this.dataSource.data);
      // let userdata=[];
      //  this.isShow=false;
     
      this.spinnerService.hide();
    
      // if (this.isShowValidate) {
      //   this.validateMessage()
      //   return;
      // }
        let set = new Set();
          let arrayList=[];
          let a=userData.map((value=>{
            set.add(value.email);
            arrayList.push(value.email);
            console.log(set);
            console.log(arrayList);
          }));
          console.log('is true',set.size==arrayList.length);
          if(set.size!=arrayList.length){
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Duplicate Elements Found ",
              text: "Email is Duplicate.",
              allowOutsideClick: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "OK"
            }).then(() => {
            });
         
            return;
          }
      
     
      let params = {
        "vendorList": userData
      }

     
      let user = JSON.parse(localStorage.getItem("currentUser"));
      let token = "Bearer" + " " + user.jwtToken;
      this.memberService
        .postVendorBulkUpload(params, token)
        .subscribe(
          (data: any) => {
            if (data.statusCode === 200) {
              this.dataSources = new MatTableDataSource(data.vendorList);
              this.vendorListArray=data.vendorList;
              console.log('Total Length',this.response.vendorList.length);
              this.dataSources.paginator=this.paginator;
              if(this.response.statusCode==200){
                this.isShow=false;
              
                Swal.fire({
                  icon: "success",
                  title: "Vendor data Submitted successfully",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  $("#newVendorCreates").modal("show");
                 });
                
        
              }
              
            }
            else if (data.statusCode === 401) {
              this.spinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Email ID already Exist",
                allowOutsideClick: false,
                showCloseButton: true
              }).then(() => {
                
              });
            }
            else if(data.statusCode === 403){
              this.spinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Invalid Request",
                text:data.statusMessage,
                allowOutsideClick: false,
                confirmButtonColor: "#d33",
               confirmButtonText: "OK"
              }).then(() => {
                
              });
            }
            else {
              this.spinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: data.statusMessage,
                allowOutsideClick: false,
                showCloseButton: true
              }).then(() => {
               
          
                
             
              });
            }
          },
          err => {
            if (err.status === 401) {
              this.spinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Something went wrong",
                allowOutsideClick: false,
                showCloseButton: true
              }).then(() => {
              });
              this.unauthorizedException();
            }
          }
        );
    }
    isMappedorNot(rolemap){
     let a= rolemap.filter((value)=>this.isMapped.includes(value.roleId)
       
      );
      return a.length==0;
  
    }
    hideVendor(){
      $("#newVendorCreates").modal("hide");
          window.location.reload();
    }
    formatPhoneNumber(event: any) {
      // Remove non-digit characters from the input
      let value = event.replace(/\D/g, '');
  
      if (value.length <= 3) {
        value = value;
      } else if (value.length <= 6) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else {
        value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
  
      // Update the form control value with the formatted value
      return value
    }
  
    getMemberListArray(memberList:any[]) {
      return memberList.map(member => ({
        'email': member['Email *'],
        'firstName': member['First Name *'],
        'LastName': member['Last Name *'],
        'groupId': this.mapwithroleId(member['Vendor Group *'])
        // 'roleId': member['User Type*'] == "Regular" ? 4 : member['User Type*'] == "Privileged" ? 5 : member['User Type*'] == "Most privileged" ? 6 : 0
      }));
    }
    mapwithroleId(Search:any){
      let a=this.groupList.filter((value=>value.name==Search)).map((value)=>value.groupId);
      return a[0];
    }
    clearExcel() {
      this.dataSource = ''
    }
  
    errorMessage() {
      this.spinnerService.hide();
      Swal.fire({
        icon: "warning",
        title: "Invalid Excel format",
        text: "Excel File Has No Data",
        allowOutsideClick: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK"
      }).then(() => {
      });
      return;
    }
  
    stripMask(phoneNumber) {
    
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const lineNumber = phoneNumber.slice(6, 10);
    
      return `(${areaCode})-${centralOfficeCode}-${lineNumber}`;
    }
  
    validateMessage() {
      this.spinnerService.hide();
      Swal.fire({
        icon: "warning",
        title: "Invalid Excel format",
        text: "Email is invalid.",
        allowOutsideClick: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK"
      }).then(() => {
      });
      return;
    }
    unauthorizedException() {
      this.spinnerService.hide();
      Swal.fire({
        icon: "warning",
        title: "Session Expired!",
        allowOutsideClick: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Logout"
      }).then(() => {
        localStorage.clear();
        this.router.navigate(["/login"]);
      });
      return;
    }
  
    getProfileUrl(img: string) {
      if (img === null) {
        return "assets/img/user.png";
      } else {
        return img;
      }
    }
}

