import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemberService } from '../shared/services/member.service';
import Swal from 'sweetalert2';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { param } from 'jquery';
declare var $: any;
@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  selectedType:string;
  searchKey:string="";
  displayedColumns: string[] = ['Name', 'Email', 'GroupName', 'CreatedDate', 'Actions'];
  dataSource: MatTableDataSource<any>;
  length:Number;
  pageNo:number=0;
  PageSize:Number=10;
  token:string;
  vendorList:any[]=[];
  vendorId:number=0;
  vendorForm!: FormGroup;
  vendorSubmitted: boolean = false;
  VendorGroupArray:any[]=[];
  groupDropDown:any[]=[];
  submitControl:boolean=false;
  heading:string='';
  footer:string='';
  // Example groupList for dropdown
  groupList :any;
  @ViewChild('parentModal', { static: false }) parentModal!: ElementRef;
  @ViewChild('childModal', { static: false }) childModal!: ElementRef;
  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder, private router: Router) {
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.GetVendorGroupList();
    this.GetVendorList(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
    this.length=this.vendorList.length;
  
    this.vendorForm = this.formBuilder.group({
      firstName: [
        '', 
        [
          Validators.required,
          WhiteSpaceValidator, // Custom validator for whitespace
          Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/) // At least 2 letters and total 3+ chars
        ]
      ],
      lastName: [
        '', 
        [
          WhiteSpaceValidator,
          Validators.required,
          Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/) // At least 1 letter; allows spaces
        ]
      ],
     
      email: [
        '', 
        [
          Validators.required,
          WhiteSpaceValidator,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) // Standard email format
        ]
      ],
      groupId: [
        '', 
        [
          Validators.required // Mandatory group selection
        ]
      ]
    });
    
  }
  
  onPaginatorClick(event: any){
   this.GetVendorList(event.pageIndex + 1, event.pageSize,this.searchKey,this.selectedType);
  }
  filterEvent(event: any){
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
    this.GetVendorList(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
    
  }
  applyFilter(){
   
    this.GetVendorList(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
  }
  onSelectChange(event: any){
    this.GetVendorList(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
  }
  GetVendorGroupList(){
    this.spinnerService.show(); 
    this.memberService.getVendorGroup(this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
         this.VendorGroupArray=data.vendorGroupList;
         this.VendorGroupArray=this.VendorGroupArray.filter(group=>group.isActive==1); 
         this.groupList= this.VendorGroupArray.map(group => ({
          id: group.groupId,
          name: group.name,
        }));

        console.log('groupList',this.VendorGroupArray);
        this.groupDropDown=this.VendorGroupArray.filter(group=>group.isActive==1);  
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }
  get vendorControl() {
    return this.vendorForm.controls;
  }
  
  hide(): void {
  this.vendorForm.reset();
  this.vendorSubmitted = false;
  $("#newVendorCreate").modal("hide");
    
  }
  checkAllFieldsEntered():boolean{
    return Object.values(this.vendorForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
    // return this.vendorForm.valid;
  }
  bulkHide(){
    $("#BulkInsert").modal("hide");
  }
 
  openbulkInsert(){
    $("#BulkInsert").modal("show");
  }
  GetVendorList(pageNo, pageSize, searchKey,selectedType) {
    let params = {
      "searchVendor": searchKey.trim(),
      "pageNo": pageNo,
      "pageSize": pageSize,
      "groupId":selectedType
    };
    this.spinnerService.show();
    // Assign the data to the data source for the table to render
    this.memberService.getVendorList(params, this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
          console.log(data.statusCode)
          console.log(data);
          this.length=data.filteredCount;
          this.vendorList=data.vendorList;
          this.dataSource = new MatTableDataSource(this.vendorList);
          this.spinnerService.hide();
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }
  onOpenVendorUpdate(row){
    this.vendorId=row.vendorId;
    this.heading='Update Vendor ';
    this.footer='Update';
    this.submitControl=false;
    console.log(this.VendorGroupArray.filter((x=>x.groupId==row.groupId)));
    this.groupDropDown=this.VendorGroupArray.filter((x=>x.groupId==row.groupId))[0].groupId;
    $("#newVendorCreate").modal("show");
   
    console.log(this.vendorForm);
    this.vendorForm = this.formBuilder.group({
      firstName: [
        row.firstName, 
        [
          Validators.required,
          WhiteSpaceValidator, // Custom validator for whitespace
          Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/) // At least 2 letters and total 3+ chars
        ]
      ],
      lastName: [
        row.lastName, 
        [
          WhiteSpaceValidator,
          Validators.required,
          Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/) // At least 1 letter; allows spaces
        ]
      ],
     
      email: [
        row.email, 
        [
          Validators.required,
          WhiteSpaceValidator,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) // Standard email format
        ]
      ],
      groupId: [
       this.groupDropDown, 
        [
          Validators.required // Mandatory group selection
        ]
      ]
    });
  }
  onOpenVendorInsert(){
    this.heading='Add Vendor ';
    this.footer='Add';
    this.submitControl=true;
    $("#newVendorCreate").modal("show");
    this.vendorForm = this.formBuilder.group({
      firstName: [
        '', 
        [
          Validators.required,
          WhiteSpaceValidator, // Custom validator for whitespace
          Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/) // At least 2 letters and total 3+ chars
        ]
      ],
      lastName: [
        '', 
        [
          WhiteSpaceValidator,
          Validators.required,
          Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/) // At least 1 letter; allows spaces
        ]
      ],
     
      email: [
        '', 
        [
          Validators.required,
          WhiteSpaceValidator,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) // Standard email format
        ]
      ],
      groupId: [
        '', 
        [
          Validators.required // Mandatory group selection
        ]
      ]
    });
    

  }
vendorFormSubmit(){
    this.vendorSubmitted = true;
    if (!this.vendorForm.valid){
      return;
    }
    
   
    this.vendorSubmitted = false;
    let params=this.vendorForm.value;
    console.log(params);
    
    this.memberService.postVendorInsert(params,this.token).subscribe(
      (data: any) => {
        
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
          $("#newVendorCreate").modal("hide");
          Swal.fire({
            title: "Created!",
            text: "Vendor created successfully",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();
          this.selectedType="0";
        
          this.GetVendorList(1,10,'',0);
         
        
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  
}
vendorFormUpdateSubmit(){
  
  this.vendorForm.addControl('vendorId', new FormControl(this.vendorId));
  this.vendorSubmitted = true;
  if (!this.vendorForm.valid){
    return;
  }
  this.vendorSubmitted = false;
  let params=this.vendorForm.value;
  console.log(params);
  
  this.memberService.postVendorUpdate(params,this.token).subscribe(
    (data: any) => {
    
      // this.ishubspotLoading = false;
      if (data.statusCode == 200) {
        $("#newVendorCreate").modal("hide");
        Swal.fire({
          title: "Updated!",
          text: "Vendor Updated successfully",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false
        });
        this.selectedType="0";
        
        this.GetVendorList(1,10,'',0);
        // this.ngOnInit();
        this.spinnerService.hide();
      
      }
      else {
        // this.isLoading = false;
        Swal.fire({
          title: data.statusMessage,
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        });
        this.spinnerService.hide();

      }
    },
    err => {
      if (err.status === 401) {
        this.spinnerService.hide();
        this.unauthorizedException();
      }
    }
  );

}
onDeleteSubmit(row: any) {
  console.log('row',row.vendorId);
  Swal.fire({
    title: "Delete Vendor",
    text: "Are you sure you want to delete this vendor? Doing so will also delete all history and data associated with this vendor.",
    icon: "warning",
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      this.spinnerService.show();

      this.memberService
        .deleteVendor({ VendorId: row.vendorId}, this.token) // Assuming `row.id` is the correct memberId
        .subscribe(
          () => {
            this.spinnerService.hide();
            Swal.fire({
              title: "Deleted!",
              text: "Vendor is deleted now",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false,
            }).then(() => {
              this.selectedType="0";
              this.GetVendorList(1,10,'',0); // Refresh data after deletion
            });
          },
          (err) => {
            this.spinnerService.hide();
            if (err.status === 401) {
              this.unauthorizedException();
            } else {
              Swal.fire({
                title: "Error!",
                text: "An error occurred while deleting the vendor.",
                icon: "error",
                showCloseButton: true,
                allowOutsideClick: false,
              });
            }
          }
        );
    }
  });
}
 unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
}
