// Vendor Imports
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserIdleModule } from "angular-user-idle";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMaskModule } from "ngx-mask";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog"
import { EditorModule } from "@tinymce/tinymce-angular";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
// Local Imports
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { LoginComponent } from "./login/login.component";
import { MembersComponent } from "./members/members.component";
import { HubspotMembersComponent } from "./hubspot-members/hubspot-members.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { NoUserAuthGuard } from "./shared/auth/no-user-auth.guard";
import { MemberViewComponent } from "./member-view/member-view.component";
import { MemberSubscriptionDetailsComponent } from "./member-subscription-details/member-subscription-details.component";
import { RegisterationComponent } from "./registeration/registeration.component";
import { UpdatesComponent } from './updates/updates.component';
import { UpdateCommentsComponent } from './update-comments/update-comments.component';
import { CouponDiscountComponent } from './coupon-discount/coupon-discount.component';
import { MemberRequestListComponent } from './member-request-list/member-request-list.component';
import { ImaMembersComponent } from './ima-members/ima-members.component';
import { ConnectMembersComponent } from './connect-members/connect-members.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { HelpComponent } from './help/help.component';
import { ModalComponent } from "./modal/modal.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TermsComponent } from './terms/terms.component';
import { RolemanageComponent } from './rolemanage/rolemanage.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { SharepointComponent } from './sharepoint/sharepoint.component';
import { ChatsComponent } from './chats/chats.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SettingsComponent } from './settings/settings.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { OrganizationDigitalIdcardComponent } from './organization-digital-idcard/organization-digital-idcard.component';
import { ManagesubscriptionComponent } from './managesubscription/managesubscription.component';
import { SuperadminLoginComponent } from './superadmin-login/superadmin-login.component';
import { OrganisationSmtpComponent } from "./organisation-smtp/organisation-smtp.component";
import { EventComponent } from './event/event.component';
import { BlogComponent } from './blog/blog.component';
import { ManageusertypeComponent } from './manageusertype/manageusertype.component';
import { ModulemanageComponent } from './modulemanage/modulemanage.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuperAdminForgotPasswordComponent } from './super-admin-forgot-password/super-admin-forgot-password.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BuysubscriptionComponent } from './buysubscription/buysubscription.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendorGroupComponent } from './vendor-group/vendor-group.component';
import { CatogoryComponent } from './catogory/catogory.component';
import { VendorconnectComponent } from './vendorconnect/vendorconnect.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    MembersComponent,
    HubspotMembersComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    MemberViewComponent,
    MemberSubscriptionDetailsComponent,
    RegisterationComponent,
    UpdatesComponent,
    UpdateCommentsComponent,
    CouponDiscountComponent,
    MemberRequestListComponent,
    ImaMembersComponent,
    ConnectMembersComponent,
    SearchFilterPipe,
    HelpComponent,
    ModalComponent,
    TermsComponent,
    RolemanageComponent,
    AnnouncementComponent,
    SharepointComponent,
    ChatsComponent,
    SidebarComponent,
    SettingsComponent,
    OrganisationComponent,
    OrganizationDigitalIdcardComponent,
    OrganisationSmtpComponent,
    ManagesubscriptionComponent,
    SuperadminLoginComponent,
    EventComponent,
    BlogComponent,
    ManageusertypeComponent,
    ModulemanageComponent,
    VideoUploadComponent,
    DashboardComponent,
    SuperAdminForgotPasswordComponent,
    AdminProfileComponent,
    SidenavComponent,
    BuysubscriptionComponent,
    HelpdeskComponent,
    VendorComponent,
    VendorGroupComponent,
    CatogoryComponent,
    VendorconnectComponent
  ],
  imports: [
    BrowserModule,
    EditorModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SweetAlert2Module,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatExpansionModule,
    NgSelectModule,
    MatRadioModule,
    MatBadgeModule,
    NgxMaskModule.forRoot(),
    
    UserIdleModule.forRoot({ idle: 1800, timeout: 1, ping: 1800 })
  ],
  providers: [AuthGuard, NoUserAuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
