<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">

<div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
    <app-header></app-header>
    <div class="main-block">
    <div class="d-flex justify-content-between wrap-block create-col">
        <div class="mt-2 create-btn">
            <!-- <button type="button" class="btn btn-secondary btn-sm" style="background-color: #4C3A67;"
                (click)="openAddMemberModal()">
                Create Announcements
            </button> -->

            <ng-container *ngIf="!isArchiveList; else getAnnouncementsButton">
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                    (click)="openAddMemberModal()">
                    Create Announcement
                </button>
            </ng-container>
            <ng-template #getAnnouncementsButton>
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                    (click)="getAnnouncementList(1,10,'',0)">
                    Get Announcement Lists
                </button>
            </ng-template>
        </div>

        <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
            <!-- YourComponent.html -->
            <div style="margin-right: 20px;" *ngIf="!isArchiveList" class="form-search-sub ">
                <mat-form-field style="width: 100%; margin-top: auto;">
                    <mat-select [(value)]="selectedType" style="margin-top: auto;" placeholder="User Type"
                        (selectionChange)="onSelectChange($event)" panelClass="custom-dropdown-panel"
                        disableOptionCentering>
                        <mat-option [value]="0">None</mat-option>
                        <mat-option *ngFor="let role of roleList?.roleType" [value]="role.id">{{role.role}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div>
                <div style="  display: flex; align-items: center;" class="archive-and-search">
                    <div style="margin-right: 20px;">
                        <ng-container *ngIf="!isArchiveList">
                            <!-- <mat-icon style="width: 30px; height: 30px;" matTooltip="Archive_List"
                                (click)="getarchiveAnnouncement()" style="cursor: pointer;">archive</mat-icon> -->
                                <div matTooltip="Archive_List" class="archive-list"
                                (click)="getarchiveAnnouncement()" style="cursor: pointer;">
                            <img src="assets/img/archive.png" style="cursor: pointer;"/>
                        </div>
                        </ng-container>
                    </div>

                    <div class="search-form-field">
                        <mat-form-field>
                            <input matInput (keyup)="filterEvent($event)" [(ngModel)]="searchKey"
                                placeholder="Type to Search" />
                        </mat-form-field>
                        <mat-icon class="searchIcon" (click)="applyFilter()" style="cursor: pointer;">search</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div style="margin-right: 95px; display: flex; align-items: center;">
            <div style="margin-right: 10px;">
                <ng-container *ngIf="!isArchiveList">
                    <mat-icon style="width: 30px; height: 30px;" matTooltip="Archive_List"
                        (click)="getarchiveAnnouncement()" style="cursor: pointer;">archive</mat-icon>
                </ng-container>
            </div>

            <div>
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
                </mat-form-field>
            </div>
        </div> -->

    </div>

    <div class="mat-elevation-z8 table-container" style="margin-bottom: 60px">
        <table mat-table [dataSource]="dataSource" matSort class="announce-table">
            <!-- <ng-container matColumnDef="announcement_image">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Announcement Images
                </th>
                <td mat-cell *matCellDef="let row"><img [src]="row.announcementUrl"
                        style="width: 60px;height: 60px;padding: 10px;"></td>
            </ng-container> -->
            <ng-container matColumnDef="title">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Title
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%;padding-right: 20px;">{{ row.title }}</td>
            </ng-container>

            <ng-container matColumnDef="roles" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected User Type(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;">
                    <ng-container *ngFor="let role of row.userRoles; let last = last">
                        {{ role.roleName }}
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="Vendor" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Vendor Group(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;">
                    <ng-container *ngFor="let role of row.vendorGroupList   ; let last = last">
                        <span *ngIf="row.vendorGroupList.length==0">N/A</span>
                        {{ role.vendorGroupName==''?'N/A': role.vendorGroupName }}
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="category" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected Category/Sub Category(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;" >
                         
                            <div class="view-icon" style="cursor: pointer;"  matTooltip="View Category/Sub Category " (click)="openSubCategoryModel(row)" *ngIf="row.category.categoryList!=null"> <img src="assets/img/view-light.png"  /></div> 
                     </td>
            </ng-container>
            <!-- <ng-container matColumnDef="SubCategory" >
                
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Selected SubCategory(s)
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;padding-left: 20px;padding-right: 20px;">
                    <ng-container *ngFor="let role of row.userRoles; let last = last">
                        {{ role.roleName }}
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container> -->

            <ng-container matColumnDef="create_date" >
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Date Created On
                </th>
                <td mat-cell *matCellDef="let row" style="width: 10%;padding-left: 20px;padding-right: 20px;">
                    {{row.created_date ? (row.created_date | date:'dd/MM/yyyy') : 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let row" style="width:11%;">
                    <!-- <mat-icon class="mr-2" matTooltip="View" class="view-icon" style="cursor: pointer;"
                        (click)="openAnnouncement(row.id)">open_in_new</mat-icon> -->
                        <div class="view-icon" style="cursor: pointer;"
                        (click)="openAnnouncement(row.id)" matTooltip="View">
                            <img src="assets/img/view-light.png" />
                        </div>
                    <!-- <mat-icon class="mr-2" [matTooltip]="row.isArchived == 1? 'Unarchive' : 'Archive'"
                        (click)="archiveAnnouncement(row)" style="cursor: pointer;" class="archive-icon">{{ row.isArchived ==1 ?
                        'unarchive' : 'archive' }}</mat-icon> -->
                        <div [matTooltip]="row.isArchived == 1? 'Unarchive' : 'Archive'"
                        (click)="archiveAnnouncement(row)" style="cursor: pointer;" class="suspend-icon">
                        <img [src]="row.isArchived == 1 ? 'assets/img/archive.png' : 'assets/img/unarchive.png'" />

                        </div>
                    <!-- <mat-icon class="mr-2" matTooltip="Edit" class="edit-icon" (click)="editAnnouncement(row)"
                        style="cursor: pointer;">edit</mat-icon> -->
                        <div class="edit-icon" (click)="editAnnouncement(row)"
                        style="cursor: pointer;" matTooltip="Edit">
                            <img src="assets/img/view.png" >
                        </div>
                    <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;"
                        (click)="deleteAnnoucment(row.id)" class="delete-icon">delete</mat-icon> -->
                        <div class="delete-icon" style="cursor: pointer;"
                        (click)="deleteAnnoucment(row.id)" matTooltip="Delete">
                            <img src="assets/img/delete.png" />
                          </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="this.users?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
            <p>no records found</p>
        </div>

        <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [length]="totalItems"
            [(pageSize)]="pageSize" [(pageIndex)]="currentPage" (page)="onPaginatorClick($event)">
        </mat-paginator>
    </div>

</div>

    <div class="modal fade" id="newAnnouncementCreate" role="dialog" aria-labelledby="staticBackdropLabel"
        style="margin-top: 50px;">

        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">{{typeofAnnounce}} Announcement</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        <!-- <img src="assets/img/modal-close.png" /> -->
                        <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                    </button>
                </div>

                <div *ngIf="addAnnouncementForm">

                    <form [formGroup]="announcementForm" (ngSubmit)="saveAnnouncement()">
                        <div style="margin: 20px;">
                            <div class="form-group mb-3">
                                <label for="firstName">Heading<span style="color: red;"> *</span> </label>

                                <input class="form-control signup-input" formControlName="title"
                                    [readonly]="typeofAnnounce == 'Edit' ?true: false">

                                <div *ngIf="announcementSubmit && memberControl.title.errors" class="ml-2">
                                    <div *ngIf="memberControl.title.errors.required" class="text-danger">
                                        Title required
                                    </div>
                                    <div *ngIf="memberControl.title.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.title.errors.noWhiteSpace }}
                                    </div>
                                    <div *ngIf="memberControl.title.errors.pattern" class="text-danger">
                                        Please ensure title more than one character
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin: 20px;">
                            <div class="form-group mb-3">
                                <label for="firstName">Short Title <span style="color: red;"> *</span> </label>

                                <input class="form-control signup-input" formControlName="shortTitle"
                                    [readonly]="typeofAnnounce == 'Edit' ?true: false">

                                <div *ngIf="announcementSubmit && memberControl.shortTitle.errors" class="ml-2">
                                    <div *ngIf="memberControl.shortTitle.errors.required" class="text-danger">
                                        Short Title required
                                    </div>
                                    <div *ngIf="memberControl.shortTitle.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.shortTitle.errors.noWhiteSpace }}
                                    </div>
                                    <div *ngIf="memberControl.shortTitle.errors.pattern" class="text-danger">
                                    Please ensure Short title more than one character
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin: 20px;">
                            <div class="form-group mb-3">
                                <label for="firstName">Short Description <span style="color: red;"> *</span> </label>
                                <!-- <mat-form-field style="width: 100%;"> -->
                                <input class="form-control signup-input" formControlName="shortDescription"
                                    [readonly]="typeofAnnounce == 'Edit' ?true: false">
                                <!-- </mat-form-field> -->
                                <div *ngIf="announcementSubmit && memberControl.shortDescription.errors" class="ml-2">
                                    <div *ngIf="memberControl.shortDescription.errors.required" class="text-danger">
                                        Short Description required
                                    </div>
                                    <div *ngIf="memberControl.shortDescription.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.shortDescription.errors.noWhiteSpace }}
                                    </div>
                                    <div *ngIf="memberControl.shortDescription.errors.pattern" class="text-danger">
                                        Please ensure short description more than one character
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div style="margin: 20px;">
                            <div class="form-group mb-3">
                              <label for="firstName">SharePoint Link
                              
                              </label><br>
                              <textarea formControlName="sharePointLink"
                                        class="form-control signup-input" 
                                        placeholder="SharePoint Link"
                                        [readonly]="typeofAnnounce === 'Edit'"></textarea>
                               <div *ngIf="announcementSubmit && memberControl.sharePointLink.errors" class="ml-2">
                              
                                <div *ngIf="memberControl.sharePointLink.hasError('pattern')" class="text-danger">
                                  Please enter a valid link.
                                </div>
                              </div>
                            </div>
                          </div>
                          

                        <div style="margin: 20px;">
                            <label for="firstName">Select User Type <span style="color: #ff0000;">*</span></label><br>
                            <mat-checkbox *ngFor="let role of roleList?.roleType" color="primary"
                                style="margin-right: 15px;" [checked]="selectedRoles.includes(role.id)"
                                [disabled]="apiselectedRoles.includes(role.id)"
                                (change)="toggleRoleSelection(role.id)" >  {{role.role}}</mat-checkbox>
                            <div *ngIf="isSelectedRoles" class="ml-2">
                                <div class="text-danger">
                                    Usertype required
                                </div>
                            </div>
                        </div>
                       

                        <div style="margin: 20px;">
                            <label for="firstName">Description
                                <span style="color: red;">*</span></label><br>
                            <editor apiKey="3utpg99m95qhctxkp123473e8ic0u4gp7hq448q0avxvazor"
                                [disabled]="typeofAnnounce == 'Edit' ? true : false" formControlName="html" [init]="{
                                        height: 400,
                                        plugins: ['image', 'lists link image table code help wordcount', 'save'],
                                        toolbar:
                                          'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help | save',
                                        images_upload_handler: this.uploadImage.bind(this)
                                      }"></editor>
                            <!-- <editor apiKey="xdkenger2ocg52ups58llhfazuqsfl4o58n4u5umy0k0zsqr"
                                [disabled]="typeofAnnounce == 'Edit' ?true: false" formControlName="html" [init]="{
                            height: 400,
                         
                            plugins: [
                            'image',
                            'lists link image table code help wordcount',
                            'save'
                            ],
                            toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | save',
                            images_upload_handler: this.uploadImage.bind(this),
                            file_picker_types: 'image'
                            
                        }"></editor> -->
                            <div *ngIf="announcementSubmit && memberControl.html.errors" class="ml-2">
                                <div *ngIf="memberControl.html.errors.required" class="text-danger">
                                    Description required
                                </div>
                                <div *ngIf="memberControl.html.errors.pattern" class="text-danger">
                                    Please ensure content more than one character
                                </div>
                            </div>
                            
                            <div class="form-group mb-3">
                                <label for="roleType">Catagory </label>
                                <select class="form-control" formControlName="catagory" id="roleType" placeholder="roleType" style="
                                                        padding-left: 15px;" (change)="oncatogorySelect($event)">
                                  <option value=0 >Select Catogory</option>
                                  
                                  <option *ngFor="let role of this.catagoryDropDown" value="{{role.categoryId}}"  [disabled]="this.categoryMap.includes(role.categoryId)">{{role.categoryName}}</option>
                                </select>
                               
                                
                              </div>
                          
                              <div class="form-group mb-3" *ngIf="isPresent">
                                <label for="roleType">Sub Catagory <span style="color: red;" *ngIf="this.subcategoryMap.length==0"> *</span></label>
                                <!-- <select class="form-control" formControlName="subcatogorycatagory" id="roleType" placeholder="roleType" style="
                                                        padding-left: 15px;" (change)="onSubcatogorySelect($event)">
                                  <option value=0 >Select Sub Category</option>
                             
                                  <option *ngFor="let role of  this.subCatagoryDropDown" value="{{role.categoryId}}" [disabled]="this.subcategoryMap.includes(role.categoryId)">{{role.categoryName}}</option>
                                </select> -->
                                
                            <!-- <ng-select  style="font-size: 1rem;"
                              bindLabel="name"                
                              bindValue="id"
                              placeholder="Select item"         
                              formControlName="subcatogorycatagory"
                              [multiple]="true"              
                              [closeOnSelect]="false"
                              (change)="onSelectGroups($event,'sub')" >
                            
                             <ng-option *ngFor="let role of SubCategoryWithAll" 
                             [value]="role.categoryId" 
                             [disabled]="this.subcategoryMap.includes(role.categoryId) ||allhideSub && role.categoryId !== 'all'">
                             {{role.categoryName}}
                            </ng-option>
                          
                            </ng-select>  -->
                            <ng-select  class="custom-ng-select"
                            bindLabel="name"                
                            bindValue="id"
                            placeholder="Select item"         
                            formControlName="subcatogorycatagory"
                            [multiple]="true"              
                            [closeOnSelect]="false"
                            (change)="onSelectGroups($event,'sub')" >
                          <ng-option [value]="'all'" [disabled]="allhideSubAll  "  *ngIf="this.subcategoryMap.length==0 && subCatagoryDropDown.length!=1 " >
                            All
                          </ng-option>
                           <ng-option *ngFor="let role of subCatagoryDropDown" 
                           [value]="role.categoryId" 
                           [disabled]="allhideSub || this.subcategoryMap.includes(role.categoryId)">
                           {{role.categoryName}}
                          </ng-option>
                        
                          </ng-select> 
                              </div>
                               <!-- <div class="form-group mb-3" >
                                <label>Vendor Group</label>
                                <select class="form-control" id="groupId" formControlName="groupId">
                                    <option value=0 >Select Group</option>
                                  
                                    <option *ngFor="let group of this.groupList" value="{{this.group.id}}" [disabled]="this.vendorMap.includes(group.id) && !this.isShow">{{ group.name }}</option>
                                  </select>
                                
                              </div> -->
                              <div class="form-group mb-3" *ngIf="this.vendorMap.length!=this.groupDropDown.length" >
                            <label for="">Vendor Group</label>
                            <ng-select  style="font-size: 1rem;"  class="custom-ng-select" 
                            bindLabel="name"                
                            bindValue="id"
                            placeholder="Select item"         
                            formControlName="groupId"
                            [multiple]="true"              
                            [closeOnSelect]="false"
                             dropdownPosition=bottom
                            (change)="onSelectGroups($event,'group')" >
                            <ng-option [value]="'all'" [disabled]="allhidegroupAll  " *ngIf="this.vendorMap.length==0 && groupList.length!=1 " >
                              All
                            </ng-option>
                           <ng-option *ngFor="let group of groupList" 
                           [value]="group.id" 
                           [disabled]="allhide||this.vendorMap.includes(group.id)">
                           {{ group.name }}
                          </ng-option>
                        
                          </ng-select>   

                             
                              </div>
                              
                              <div class="form-group mb-3">
                                <Label>Announcement Image <span style="color: red;">*</span></Label><br *ngIf="!isShow">
                                <a [href]
                                ="logoDisplay" *ngIf="!isShow"> <span *ngIf="!isShow">view Image</span>
                                </a><br>
                                <img [src]="logoDisplay" *ngIf="!isShow" alt="Profile Image" width="50" height="50" style="margin: 4px;" >
                                <!-- <a [href]="logoDisplay" *ngIf="!isShow"> <span *ngIf="!isShow">view Image</span>
                                </a><br> -->
                                <input type="file" (change)="onFileSelect($event)" *ngIf="isShow" formControlName="logoimg" >
                            </div>
                            <div class="text-danger" *ngIf="customvalidator"> Please upload an image in GIF, JPEG, or
                                PNG format. </div>
                            <div class="footer-modal"> 
                                <button [disabled]="!checkAllFieldsEntered()" class="sync-btn">Submit</button>
                               
                            </div>
                        </div>
                    </form>
                   
                </div>
            </div>
        </div>

    </div>


    <div class="modal fade" id="viewAnnouncementHistory" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title"> Announcement Viewed User List</h4>
                        <button type="button" class="close" data-dismiss="modal" (click)=" viewAnnouncementHistoryHide()">
                            <!-- <img src="assets/img/modal-close.png" /> -->
                            <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body mb-3">
                        <!-- Coupon Discount Form -->
                        <div class="modal-body">

                            <div style="display: -ms-flexbox;
                              display: flex;
                              -ms-flex-direction: row;
                              justify-content: space-between;
                              flex-direction: row;">
                                <div style="width: 30%;">
                                    <h5 class="headerstyle">User Name</h5>
                                </div>

                                <div style="width: 30%;">
                                    <h5 class="headerstyle">User Type</h5>
                                </div>

                                <div style="width: 30%;">
                                    <h5 class="headerstyle">Viewed Date</h5>
                                </div>
                            </div>
                            <div class="empty" *ngIf="isEmpty==0" style="margin-top: 40px;">
                                <p style="display: flex; justify-content: center;align-items: center;">no records found
                                </p>
                            </div>
                            <div class="nonempty" *ngIf="isEmpty!=0">
                                <div style="display: -ms-flexbox;margin-top: 20px;
                                display: flex;
                                -ms-flex-direction: row;
                                justify-content: space-between;
                                flex-direction: row;" *ngFor="let item of this.viewUserdata">

                                    <div style="width: 30%;">
                                        <p>{{item.memberName}}</p>
                                    </div>

                                    <div style="width: 30%;">
                                        <p>{{item.memberUserType}}</p>
                                    </div>

                                    <div style="width: 30%;" class="content-center">
                                        <p style="text-align: left;">{{item.chatCreatedAt}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="SubModelShow" role="dialog" aria-labelledby="staticBackdropLabel"
     style="margin-top: 50px;">

    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Category/Subcategory Details</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <div class="mat-elevation-z8 table-container">
                <table mat-table [dataSource]="dataSources" matSort class="announce-table">
                    <ng-container matColumnDef="Category">
                        <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let row" style="width: 30%;padding-right: 20px;">{{ row.parentCategory }}</td>
                    </ng-container>
                    <ng-container matColumnDef="SubCategory">
                        <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                            SubCategory
                        </th>
                        <td mat-cell *matCellDef="let row" style="width: 30%;padding-right: 20px;">
                            <span *ngFor="let row of row.subCategory; let last = last">
                               {{row.subCategory}}
                         
                            <span *ngIf="!last">, </span> 
                        </span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                
                </table>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <ngx-spinner size="default" color="#fff" type="square-loader" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>