import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { AnnouncementListResponse, ViewAnnouncementListResponse } from '../shared/models/user';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { element } from 'protractor';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SharedalertService } from '../shared/services/sharedalert.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

export interface UserData {
  id: Number;
  title: string;
  userRoles: userRoles;
  created_date: string;
}
export interface userRoles {
  roleId: Number;
  roleName: string;
}

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  // Array to hold selected groups
  selectedGroups: number[] = [];
  html = '<p></p>';
  customvalidator:boolean=false;
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  logoHide: boolean = true;
  isShow:boolean=true;
  logoDisplay: string;
  token: string;
  roleList: any;
  selectedFile:File=null;
  title: any;
  rolevalue: any;
  dataSource: MatTableDataSource<any>;
  dataSources: MatTableDataSource<any>;

  users: UserData[];
  filter: string;
  typeofAnnounce: string = "Create"
  displayedColumns: string[] = [
  
    "title",
    "roles",
    "Vendor",
    "category",
    "create_date",
    "actions"
  ];
  displayedColumn:string[]=[
    "Category",
    "SubCategory"
  ]

  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  addAnnouncementForm: boolean;
  announcementId = 0;
  selectedRoles: string[] = [];
  istitleError: boolean;
  ishtmlError: boolean;
  isRoleError: boolean;
  announcementForm: FormGroup;
  announcementSubmit: boolean;
  resizedBlob: Blob | null = null;
  isSelectedRoles: boolean;
  apiselectedRoles: string[] = [];
  isArchiveList: boolean;
  viewUserdata: any[];
  isEmpty:number;
  searchKey: any = '';
  selectedType: Number
  pageSize = 10;
  currentPage = 0;
  totalItems: any;
  uniqueCode: string;
  titles:string;
  content:string;
  shortTitle:string;
  emptyArray:[]=[];
  shortDescription:string;
  catagoryDropDown: any[];
  subCatagoryDropDown:any[];
  subcategoryMap:any[]=[];
  isPresent: boolean=false;
  VendorGroupArray: any[];
  groupList: any[]=[];
  vendorMap:any[]=[];
  groupDropDown: any[];
  subcategoryDropDowns: any[];
  subCatagoryDropDowns: any;
  announcementList = [
    {
      id: 1,
      title: "CSUMEVents",
      content: "<p>Event Details</p>",
      userRoles: [{ roleId: 101, roleName: "Admin" }],
      created_date: "2024-12-01T10:30:00",
      isArchived: 0,
      shortTitle: "CSUME",
      shortDescription: "CSUMEVents Short",
      announcementUrl: "https://example.com/image1.png",
      category: {
        currentParentCategoryId: 24,
        categoryList: [
          {
            parentCategoryId: 24,
            parentCategory: "Employee",
            subCategory: [
              { subCategoryId: 23, subCategory: "IT", parentCategoryId: 24 }
            ]
          },
          {
            parentCategoryId: 25,
            parentCategory: "Demolyee",
            subCategory: [
              { subCategoryId: 27, subCategory: "Finace", parentCategoryId: 26 }
            ]
          },

        ]
      },
      vendorGroupList: [
        { vendorGroupId: 9, vendorGroupName: "Finance Vendor" },
        { vendorGroupId: 10, vendorGroupName: "Vendor Family" }
      ]
    },
    {
      id: 2,
      title: "HRMeet",
      content: "<p>HR Meet</p>",
      userRoles: [{ roleId: 102, roleName: "HR" }],
      created_date: "2024-12-02T09:00:00",
      isArchived: 0,
      shortTitle: "HR",
      shortDescription: "HR Meet Short",
      announcementUrl: "https://example.com/image2.png",
      category: {
        currentParentCategoryId: 25,
        categoryList: [
          {
            parentCategoryId: 25,
            parentCategory: "Human Resources",
            subCategory: [
              { subCategoryId: 24, subCategory: "Recruitment", parentCategoryId: 25 }
            ]
          }
        ]
      },
      vendorGroupList: [
        { vendorGroupId: 9, vendorGroupName: "Finance Vendor" }
      ]
    },
    {
      id: 3,
      title: "FinanceTalks",
      content: "<p>Finance Event</p>",
      userRoles: [{ roleId: 103, roleName: "Finance" }],
      created_date: "2024-12-03T14:00:00",
      isArchived: 0,
      shortTitle: "Finance",
      shortDescription: "Finance Short",
      announcementUrl: "https://example.com/image3.png",
      category: {
        currentParentCategoryId: 26,
        categoryList: [
          {
            parentCategoryId: 26,
            parentCategory: "Finance",
            subCategory: [
              { subCategoryId: 25, subCategory: "Accounting", parentCategoryId: 26 }
            ]
          }
        ]
      },
      vendorGroupList: [
        { vendorGroupId: 9, vendorGroupName: "Finance Vendor" }
      ]
    },
    {
      id: 4,
      title: "TechSummit",
      content: "<p>Tech Event</p>",
      userRoles: [{ roleId: 104, roleName: "Developer" }],
      created_date: "2024-12-04T16:00:00",
      isArchived: 0,
      shortTitle: "Tech",
      shortDescription: "Tech Summit Short",
      announcementUrl: "https://example.com/image4.png",
      category: {
        currentParentCategoryId: 27,
        categoryList: [
          {
            parentCategoryId: 27,
            parentCategory: "Technology",
            subCategory: [
              { subCategoryId: 26, subCategory: "AI", parentCategoryId: 27 }
            ]
          }
        ]
      }
      ,
      vendorGroupList: [
        { vendorGroupId: 9, vendorGroupName: "Finance Vendor" }
      ]
    },
    {
      id: 5,
      title: "SalesMeet",
      content: "<p>Sales Event</p>",
      userRoles: [{ roleId: 105, roleName: "Sales" }],
      created_date: "2024-12-05T11:00:00",
      isArchived: 0,
      shortTitle: "Sales",
      shortDescription: "Sales Meet Short",
      announcementUrl: "https://example.com/image5.png",
      category: {
        currentParentCategoryId: 28,
        categoryList: [
          {
            parentCategoryId: 28,
            parentCategory: "Sales",
            subCategory: [
              { subCategoryId: 27, subCategory: "Marketing", parentCategoryId: 28 }
            ]
          }
        ]
      },
      vendorGroupList: [
        { vendorGroupId: 9, vendorGroupName: "Finance Vendor" }
      ]
    }
  ];

  userTypeMap: any[]=[];
  userRoles: any[]=[];
  ismandate: boolean=false;
  categoryMap: any[]=[];
  disable: boolean=false;
  groupListWithAll: any[];
  allhide: boolean;
  Allvendormap: any[];
  SubCategoryWithAll: any[];
  AllSubcategoryMap: any[];
  allhideSub: boolean;
  allhidegroupAll: boolean;
  allhideSubAll: boolean;
  subcategoryMaps: any[]=[];
  editObject: any;
  subcategoryNames: any;
  parentName: any;
  parentCategory: any;
  shareLink: string;
  parentmap: any;
  // selectedRoles: number[] = []; // Assuming you have this property
  // roleList: any; // Assuming you have this property
  constructor(private spinnerService: NgxSpinnerService, private cdr: ChangeDetectorRef,private route: ActivatedRoute, private sharedService: SharedalertService, private memberService: MemberService, private formBuilder: FormBuilder) {
    this.announcementForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      shortTitle:['',[Validators.required, WhiteSpaceValidator]],
      shortDescription:['',[Validators.required, WhiteSpaceValidator]],
      logoimg:['',[Validators.required, WhiteSpaceValidator]],
      groupId:['',[Validators.required]],
      sharePointLink: ['', [ Validators.pattern(/^(http:\/\/|https:\/\/).+$/)]],
      catagory:[0,[]],
      subcatogorycatagory:[0,[]]
    });
    
    
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    
  this.getCatogoryList();
    // this.route.paramMap.subscribe(params => {
    //   this.uniqueCode = params.get('uniqueCode');
    //   // Use the uniqueCode as needed
    //   console.log(this.uniqueCode); // Example: log the uniqueCode
    // });
  this.GetVendorGroupList();
  this.getRoles();
  this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType);
    // this.checkAllFieldsEntered();
    // this.getarchiveAnnouncement()
    
 
  }
  openSubCategoryModel(row:any){
    if(row.category.categoryList!=null){
    this.parentCategory=row.category.categoryList;
    this.dataSources = new MatTableDataSource(row.category.categoryList);
    console.log('Parent Category',this.parentCategory);
    let subcategoryMap=row.category.categoryList.map((value)=>value.subCategory);
    let subactegoryname=subcategoryMap.flat();
    console.log('SubCategoryMAp',subactegoryname);
    // console.log(subactegoryname);
    // this.subcategoryNames=subactegoryname.join();
   console.log('Return',this.subcategoryNames);
   $("#SubModelShow").modal("show");

    }


  }
  GetVendorGroupList(){
   
    this.memberService.getVendorGroup(this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
         this.VendorGroupArray=data.vendorGroupList;
         this.VendorGroupArray=this.VendorGroupArray.filter(group=>group.isActive==1); 
         this.groupList= this.VendorGroupArray.map(group => ({
          id: group.groupId,
          name: group.name,
        }));
        if( this.groupList.length>=2){
        this.groupListWithAll = [{ id: 'all', name: 'All' }, ...this.groupList];
        }
        else{
          this.groupListWithAll=this.groupList;
        }
        console.log('groupList',this.groupListWithAll);
        this.groupDropDown=this.VendorGroupArray.filter(group=>group.isActive==1);  
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  uploadImage(blobInfo, success, failure): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64Data = e.target.result;
      success(base64Data);
    };

    reader.onerror = (error) => {
      console.error('Error reading image file:', error);
      failure('Error reading image file');
    };

    reader.readAsDataURL(blobInfo.blob());
  }
  onFileSelect(event: any) {
    this.logoHide = false;
    if(this.announcementSubmit==true||this.validTypes.includes(event.target.files[0].type)){
    this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
    }
    this.selectedFile = event.target.files[0];
      // Resize the image and get it as a Blob
      this.resizeImage(this.selectedFile ,622, 700).then((blob) => {
        this.resizedBlob = blob; // Now we have the resized Blob
      });
    
  }
  viewAnnouncementHistoryHide(){
    $("#viewAnnouncementHistory").modal("hide");
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    const reader = new FileReader();
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    return new Promise<Blob>((resolve, reject) => {
      reader.onload = (e: any) => {
        img.src = e.target.result;
      };

      img.onload = () => {
  // Original dimensions
  // let width = img.width;
  // let height = img.height;

  // // Calculate the desired aspect ratio
  // const aspectRatioWidth = 80; // 80%
  // const aspectRatioHeight = 70; // 70%

  // // Determine the scaling factor based on the desired aspect ratio
  // const scaleFactorWidth = maxWidth / aspectRatioWidth;
  // const scaleFactorHeight = maxHeight / aspectRatioHeight;
  
  // // Choose the smaller scale factor to maintain the aspect ratio within the bounds
  // const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

  // // Calculate new dimensions based on the scale factor
  // width = Math.floor((aspectRatioWidth * scaleFactor));
  // height = Math.floor((aspectRatioHeight * scaleFactor));

        // Resize the image using the canvas
        canvas.width = 314;
        canvas.height = 280;
        ctx.drawImage(img, 0, 0, 314, 280);

        // Convert the canvas to a Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob); // Return the Blob
          } else {
            reject(new Error('Canvas is empty or failed to resize image'));
          }
        }, 'image/jpeg', 0.9); // Adjust quality if needed
      };

      reader.readAsDataURL(file); // Read the image file as a Data URL
    });
  }

  
  
  oncatogorySelect(event:any){
   
    this.subcategoryMaps=[];
    console.log('Subb',this.subcategoryMaps);
    let subcatogoryList=  this.subCatagoryDropDown;
    this.isPresent=false;
    console.log('s',this.subCatagoryDropDowns);
    console.log('Event',(event.target as HTMLSelectElement).value);
    console.log('Subcatogory',this.subCatagoryDropDown);

    this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
    (x) => x.parentCategoryId == (event.target as HTMLSelectElement).value
    );
    console.log('Length',this.subCatagoryDropDown);
    
    if(this.subCatagoryDropDown.length==0 ){
      console.log('Map',this.parentmap);
      console.log('Value',(event.target as HTMLSelectElement).value);
      let n=parseInt((event.target as HTMLSelectElement).value);
      console.log(n);
      if( this.parentmap.includes(n)){
         this.categoryMap.push(n);
         this.announcementForm.controls['catagory'].setValue(0);
         return;
      }
      else{
       this.categoryMap.push()
      }
     }
    
    this.isPresent=  this.subCatagoryDropDown.length==0?false:true;
    if( this.isShow==false){
    console.log( 'Parent',this.subCatagoryDropDown[0].parentCategoryId);
    console.log(this.editObject);
    let m = this.editObject.category.categoryList
    .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==this.subCatagoryDropDown[0].parentCategoryId).map((value)=>value);
    console.log('M',m);
    
    console.log('SubCategoryMapLength',this.subCatagoryDropDown);
    console.log('SubMap',this.subcategoryMap);
    if(m.length==this.subCatagoryDropDown.length){
    this.categoryMap.push(this.subCatagoryDropDown[0].parentCategoryId);
    this.announcementForm.controls['catagory'].setValue(0);
    this.isPresent=false;
    this.announcementForm.controls['subcatogorycatagory'].setValue([]);
    }
  }
    console.log('CategoryMap',this.categoryMap);
    subcatogoryList=this.subcategoryDropDowns;
    console.log('k',this.subCatagoryDropDown);
    if(this.subCatagoryDropDown.length>=2){
    this.SubCategoryWithAll = [{ categoryId: 'all', categoryName: 'All' }, ...this.subCatagoryDropDown];
    }
    else{
      this.SubCategoryWithAll=this.subCatagoryDropDown;
    }
   
  // console.log('subCatagoryDropDownLength',this.subCatagoryDropDown);
  // let parentmap=this.editObject.category.categoryList.map((value)=>value.parentCategoryId);
  // console.log('parentMap',parentmap);
  // if(this.subcategoryMaps.length==this.subCatagoryDropDown.length){
    
  //    this.categoryMap=this.editObject.category.categoryList
  //    .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==this.editObject.category.currentCategoryId).map((value)=>value.parentCategoryId);
  //    console.log('CategoryMap',this.categoryMap);
  //    this.announcementForm.controls['catagory'].setValue(0);
  // }
    

    // subcatogoryList=this.subCatagoryDropDown;
    console.log('subcatogory',this.subCatagoryDropDown);
    
  }
  
  onSubcatogorySelect(Event:any){
  }
  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    this.paginator.pageIndex = 0;
    // You can perform additional actions or logic based on the selected value
    this.getAnnouncementList(1, this.pageSize, this.searchKey, this.selectedType);
  }

  getAnnouncementList(pageNo, pageSize, searchKey, roleType) {
    this.spinnerService.show();
    let params = {
      "searchTitle": searchKey,
      "roleId": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    }
    this.isArchiveList = false;
   
    this.memberService.getAnnouncementList(params, this.token).subscribe(
      (data: { filteredCount: Number; statusCode:number,announcementList: any }) => {
        
        let userData = this.getMemberListArray(data.announcementList);
        console.log(userData);
        this.users = userData;
        // this.dataSource = new MatTableDataSource<any>(this.announcementList);
        this.dataSource = new MatTableDataSource(data.announcementList);
        this.totalItems = data.filteredCount
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinnerService.hide();
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
        else {
          this.spinnerService.hide();
        }
      }
    );
  }

  getarchiveAnnouncement() {
    this.isArchiveList = true
    this.spinnerService.show()
    this.memberService.getArchiveannouncementList(this.token).subscribe(
      (data: { archiveList: AnnouncementListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        let userData = this.getMemberListArray(data.archiveList);
        console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };

  getMemberListArray(announcementList: AnnouncementListResponse[]) {
    this.spinnerService.show();
    return announcementList.map(member => ({
      id: member.id,
      title: member.title,
      userRoles: member.userRoles,
      content: member.content,
      created_date: this.GetFormattedDate(member.created_date),
      isArchived: member.isArchived,
      shortDescription:member.shortDescription,
      shortTitle:member.shortTitle,
      announcementUrl:member.announcementUrl,
      categoryId:member.parentCategoryId,
      categoryName:member.parentCategory,
      subcategoryName :member.subCategory,
      SubcategoryId:member.subCategoryId,
      vendorId:member.vendorGroupId,
      vendorGroupList:member.vendorGroupList,
      category:member.category,
      categoryList:member.categoryList
  
    }));
  }

  getViewMemberListArray(announcementList: ViewAnnouncementListResponse[]) {
    // this.spinnerService.show();
    return announcementList.map(member => ({
      id: member.announcementId,
      title: member.title,
      memberName: member.memberName,
      content: member.content,
      created_date: this.GetFormattedDate(member.viewedDate),
    }));
  }

  getRoles() {
   
    if (this.roleList) {
      return this.roleList;
    }
    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
          console.log('roles',data);
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }



  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getAnnouncementList(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }

  openAnnouncement(row) {
    $("#viewAnnouncementHistory").modal("show");
    this.viewUserdata = []
    let params = {
      "announcementId": row
    }
    this.memberService
      .viewAnnouncement(params, this.token)
      .subscribe(
        (data: { members: any[], statusCode: number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            // Map and format the date for each item in the members array
            const formattedData = data.members.map(item => ({
              ...item,
              chatCreatedAt: this.GetFormattedDate(item.viewedDate)
            }));
            // Assign the formatted data to this.viewUsers
            this.viewUserdata = formattedData;
            
           this.isEmpty=this.viewUserdata.length;
            console.log('Viewd data ddataatata',this.viewUserdata.length);
           

          }
        }
      );

  }



  editAnnouncement(row) {
    this.isPresent=false;
    this.shareLink=row.sharePointLink;
    this.announcementForm = this.formBuilder.group({
      title: [row.title, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      // rolevalue: ['', Validators.required],
      html: [row.content, [Validators.required, WhiteSpaceValidator]],
      shortTitle:[row.shortTitle, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      shortDescription:[row.shortDescription, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      sharePointLink: [row.sharePointLink, [Validators.pattern(/^(http:\/\/|https:\/\/).+$/)]],
      logoimg:[row.announcementUrl],
      groupId:[[]],
      catagory:[row.category.currentCategoryId],
      subcatogorycatagory:[[]]
    
    });
  // this.checkAllFieldsEntered();
  console.log('CategoryId',row.category.currentCategoryId);
  
    this.allhide=false;
    this.allhideSub=false;
    this.editObject=row;
    console.log('Row',row);
    // this.ismandate=row.category?.subCategory.length==0;
    // console.log(this.ismandate);
    this.subCatagoryDropDown=[];
    this.subcategoryMaps=[];
    this.vendorMap=[];
    this.categoryMap=[];
    this.subcategoryMap=[];
    this.isShow=false;
    this.logoHide=true;
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.addAnnouncementForm = true;
   
    this. userTypeMap=row.userRoles;
    this.userTypeMap=this.userTypeMap.map(value=>value.roleId);
    // row.categoryId=71;
    // row.SubcategoryId=72;
    if(row.category.categoryList!=null){
    if(row.category.categoryList.currentCategoryId!=0||row.category.categoryList.currentCategoryId==undefined){
       this.subcategoryMap=row.category.categoryList
       .flatMap(category => category.subCategory)  
       .map(value => value.subCategoryId);   
       console.log('SubCategory',this.subcategoryMap);
      //  subcategoryMap.'map((value) => this.subCatagoryDropDown.find((x) => x.id === value) || null).filter((x)=>x!=null);
      this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
       (x) => x.parentCategoryId == row.category.currentCategoryId
       );
       
       console.log('sub',this.subCatagoryDropDown);
       this.SubCategoryWithAll = [{ categoryId: 'all', categoryName: 'All' }, ...this.subCatagoryDropDown];
       this.isPresent=this.subCatagoryDropDown.length==0?false:true;
        console.log('present',this.isPresent);
      //  this.subCatagoryDropDown = this.subCatagoryDropDown
      //  .filter((x) => !subcategoryMap.includes(x.categoryId));
      //  if(this.subCatagoryDropDown.length==0){
      //   this.catagoryDropDown=this.catagoryDropDown.filter((x)=>x.categoryId!=row.categoryId);
      //  }

      this.subcategoryMaps = row.category.categoryList
      .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==row.category.currentCategoryId).map((value)=>value);
      console.log('SubCategoryMapLength',this.subcategoryMaps);
      console.log('subCatagoryDropDownLength',this.subCatagoryDropDown);
      this.parentmap=row.category.categoryList.map((value)=>value.parentCategoryId);
      console.log('parentMap',this.parentmap);
      if(this.subcategoryMaps.length==this.subCatagoryDropDown.length){
        
         this.categoryMap=row.category.categoryList
         .flatMap(category => category.subCategory).filter((value)=>value.parentCategoryId==row.category.currentCategoryId).map((value)=>value.parentCategoryId);
         console.log('CategoryMap',this.categoryMap);
         this.announcementForm.controls['catagory'].setValue(0);
         this.isPresent=false;
         this.announcementForm.controls['subcatogorycatagory'].setValue([]);
      }
      else{
        this.categoryMap=[];
      }
      console.log('Choose Length', this.subcategoryMaps);
       if(this.vendorMap.length==this.groupDropDown.length){
        this.announcementForm.controls['groupId'].setValue([]);
        
    
       }
        
       
    }
  }
   
  
   
  
  //  if(this.subCatagoryDropDown.length==this.subcategoryMap.length){
  //   this.categoryMap.push(row.category.parentCategoryId);
  //   this.isPresent=false;
  //   this.disable=true;
  //   // this.announcementForm.controls['catagory'].setValue(0);
  //  }

   
    
  

    
   console.log('CategoryMap',this.categoryMap);
    this.vendorMap=row.vendorGroupList.map((value)=>value.vendorGroupId);
    console.log(this.vendorMap);
    console.log('Group',this.groupList);
    // this.groupList = this.groupList.filter((item) => !vendorMap.includes(item.id));
    // console.log('Vector',this.groupList);
    // console.log('GroupList',x);
    
    this.typeofAnnounce = "Edit"
    $("#newAnnouncementCreate").modal("show");
    // this.announcementForm.controls['title'].setValue(row.title);
    // this.announcementForm.controls['html'].setValue(row.content);
    // this.announcementForm.controls['shortDescription'].setValue(row.shortDescription);
    // this.announcementForm.controls['shortTitle'].setValue(row.shortTitle);
    // this.announcementForm.controls['title'].disable();
    this.logoDisplay=row.announcementUrl;
    this.announcementId = row.id;
    this.titles=row.title,
    this.content=row.content,
    this.shortDescription=row.shortDescription;
    this.shortTitle=row.shortTitle;
    console.log(this.titles ,this.content,this.shortDescription,this.shortTitle);
    row.userRoles.forEach(element => {
      this.selectedRoles.push(element.roleId)
      this.apiselectedRoles.push(element.roleId)
    });
    console.log(this.checkAllFieldsEntered());
  }

  archiveAnnouncement(row) {
    let params = {
      "announcementId": [row.id],
      "archieve": row.isArchived == 0 ? true : false
    }
    this.memberService
      .archiveAnnouncement(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.users = this.users.filter(obj => obj.id !== row.id);
            let userData = this.users
            this.dataSource = new MatTableDataSource(userData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            const customTitle = row.isArchived === 0 ? "Announcement archived." : "Announcement unarchived.";
            this.sharedService.toast(customTitle, () => {
              // this.ngOnInit()
            });
            return;
          }
          else {
            this.spinnerService.hide();
            this.sharedService.errorAlert(() => {
              this.ngOnInit()
            });
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  deleteAnnoucment(id) {
    Swal.fire({
      title: "Delete Announcement",
      text: "Are you sure?  Do you want to delete announcement?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false,
      
    }).then(result => {
      if (result.value) {
        let params = {
          "announcementId": id
        }
        this.spinnerService.show();
        this.memberService
          .deleteAnnouncement(params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                this.sharedService.successAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.spinnerService.hide();
                this.sharedService.errorAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    })

  }
  getCategorySubCategory(row:any){
    let answer=''
    let child=[];
    if(row.category.currentCategoryId==0||row.category.currentCategoryId==undefined){
      return 'N/A'
    }
    // console.log(typeof(row.category.categoryList.subCategory));
    let container=row.category.categoryList.map((value)=>value.parentCategory);
    
     if(row.category.categoryList==null){
      return'N/A';
      
     }
     console.log(row?.category?.categoryList?.subCategory ?? []);
     if(row.category.categoryList.subCategory!=null){
      console.log('jndjbdjb')
        console.log(row.category.categoryList.subCategory);
     }
    
    
      //  let parentmap=container.map(value=>value.parentCategory);
      //  return parentmap.join();
    

    return container.join()+"/"+child.join();
  }
  // Add Member
  openAddMemberModal() {
   
    this.subcategoryMap=[];
    this.categoryMap=[];
    this.allhideSub=false;
    this.subCatagoryDropDown=[];
    this.vendorMap=[];
    this.categoryMap=[];
    this.subcategoryMap=[];
    this.allhide=false;
    this.isPresent=false;
    this.customvalidator=false;
    this.logoHide=true;
    this.isShow=true;
    this.addAnnouncementForm = true;
    this.typeofAnnounce = "Create"
    this.announcementId = 0
    $("#newAnnouncementCreate").modal("show");
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.announcementSubmit = false;
    this.isSelectedRoles = false;
    this.announcementForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      shortTitle:['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      sharePointLink: ['', [Validators.pattern(/^(http:\/\/|https:\/\/).+$/)]],
      shortDescription:['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      logoimg:['',[Validators.required, WhiteSpaceValidator]],
      groupId:[[],[]],
      catagory:[0,[]],
      subcatogorycatagory:[[],[]]
    });

  }

  get memberControl() {
    return this.announcementForm.controls;
  }
  hide() {
    this.addAnnouncementForm = false;
    this.announcementSubmit = false;
    this.isSelectedRoles = false;
    $("#newAnnouncementCreate").modal("hide");
    $("#SubModelShow").modal("hide");

  }

  toggleRoleSelection(role: string) {
    console.log('',role);
    if(! this.userTypeMap.includes(role)){
      this.userRoles.push(role);
    }
    const index = this.selectedRoles.indexOf(role);
    if (index === -1) {
      // Role is not in the array, add it
      this.selectedRoles.push(role);
    } else {
      // Role is already in the array, remove it
      this.selectedRoles.splice(index, 1);
    }
    if (this.selectedRoles.length > 0) {
      this.isSelectedRoles = false
    }
    else {
      this.isSelectedRoles = true
    }
  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }
  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchKey = filterValue
    if (this.searchKey != '' && this.searchKey != undefined)
      this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)

    // this.dataSource.filter = filterValue.trim().toLowerCase();


    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
 
  // checkAllFieldsEntered(): boolean {
  //   return Object.keys(this.announcementForm.controls).every(controlName => {
   
  //      if(controlName=='catagory' && !this.isPresent ){
        
  //       return true;
  //     }
    
  //     else if(controlName=='subcatogorycatagory'  &&  this.isPresent){
  //     const control = this.announcementForm.get(controlName);
  //      console.log('Control',control);
  //       // if(control.value==0 && this.disable){
  //       //   return true;
  //       // }
  //        if(control.value==0){
  //         return true;
  //       }
  //       return true;
  //       }
       
  //     else if(controlName=='groupId'){
  //       return true;
  //     }
  //     console.log('control',controlName);
  //     const control = this.announcementForm.get(controlName);
  //     return control.value !== '' && control.value !== null;
  //   });
  // }
  // checkAllFieldsEntered(): boolean {
  //   return Object.values(this.announcementForm.controls).every(control => {
  
  //     return control.value !== '' && control.value !== null;
  //   });
    
  //     // return Object.keys(this.announcementForm.controls).every(key => {
  //     //   // Skip Only For Update Module 
  //     //   if (key === "logoimg"&&  !this.isShow ) {
  //     //     return true; // Skip validation for 'logoimg'
  //     //   }
  //     //   const control = this.announcementForm.get(key);
  //     //   return control && control.value !== '' && control.value !== null;
  //     // });
    
  // }
  checkAllFieldsEntered(): boolean {
    return Object.keys(this.announcementForm.controls).every(controlName => {
      if (controlName === 'notes') {
        // Skip validation for the "notes" field
        return true;
      }
      if(controlName=="sharePointLink"){
        return true;
      }
      else if(controlName=='catagory' && !this.isPresent ){
        return true;
      }
      else if(controlName=='subcatogorycatagory'  && this.isPresent){
        // console.log("Abc");
        if(this.subcategoryMap.length!=0){
          return true;
        }
        const control = this.announcementForm.get(controlName);
      //  console.log('Control',control);
        // if(control.value==0 && this.disable){
        //   return true;
        // }
         if(control.value==0){
          return false;
        }
        return true;
        }
       
      else if(controlName=='groupId'){
        return true;
      }
 
      const control = this.announcementForm.get(controlName);
      // console.log(control?.value,controlName);
      return control?.value !== '' && control?.value !== null;
    });
  }
  // onSelectGroups(groups: any[],select:string ) {
    
 
  //   if (groups.includes('all') && select=='group') {
  //     let map=this.groupList.filter(value=>!this.vendorMap.includes(value.id)).map((value)=>value.id);
  //     this.allhide=true;
  //     console.log('Map',map);
  //     this.Allvendormap=map;
  //     // If "All" is selected, do nothing and prevent further actions
  //     return;
  //   }
  //   else if(groups.includes('all') && select=='sub'){
  //     let map=this.subCatagoryDropDown.filter(value=>!this.subcategoryMap.includes(value.categoryId)).map((value)=>value.categoryId);
  //     this.allhideSub=true;
  //     this.AllSubcategoryMap=map;
  //     console.log('map sub ',map);
  //   }
  //   else{
  //     this.allhide=false;
  //     this.allhideSub=false;
  //   }

  //   // Your normal selection logic for individual items
  //   console.log('Selected Groups:', groups);
  // }
  onSelectGroups(groups: any[],select:string ) {
    if(groups.length!=0 &&  select=='sub' && !groups.includes('all') ){
    this.allhideSubAll=true;
    return;
    }
    if(groups.length!=0 &&  select=='group' && !groups.includes('all') ){
      this.allhidegroupAll=true;
      return;
      }
    this.allhideSubAll=false;
    this.allhidegroupAll=false;
    if (groups.includes('all') && select=='group') {
      let map=this.groupList.filter(value=>!this.vendorMap.includes(value.id)).map((value)=>value.id);
      this.allhide=true;
      console.log('Map',map);
      this.Allvendormap=map;
      // If "All" is selected, do nothing and prevent further actions
      return;
    }
    else if(groups.includes('all') && select=='sub'){
      let map=this.subCatagoryDropDown.filter(value=>!this.subcategoryMap.includes(value.categoryId)).map((value)=>value.categoryId);
      this.allhideSub=true;
      this.AllSubcategoryMap=map;
      console.log('map sub ',map);
    }
    else{
 
      this.allhide=false;
      this.allhideSub=false;
      console.log('Mpa', this.AllSubcategoryMap)
      

    }
   
    // Your normal selection logic for individual items
    console.log('Selected Groups:', groups);
  }
  saveAnnouncement() {
    this.announcementSubmit = true;

    if (this.selectedRoles.length === 0 || this.announcementForm.invalid) {
      this.isSelectedRoles = true;

      if (this.selectedRoles.length > 0) {
        this.isSelectedRoles = false;
        return;
      }

      if (this.announcementForm.invalid) {
        return;
      }

      return; // Return if either condition is true
    }
    if(!this.logoHide){
    this.customvalidator= ! this.validTypes.includes(this.selectedFile.type);
    if(! this.validTypes.includes(this.selectedFile.type)){
     
     return;

    }
  }
    let params = {
      "title": this.announcementForm.value.title,
      "content": this.announcementForm.value.html,
      "userTypeId": this.userRoles,
      "adminId": 1,
      "announcementId": this.announcementId,
      "shortDescription":this.announcementForm.value.shortDescription,
      "shortTitle":this.announcementForm.value.shortTitle,
      "announcementImage":this.selectedFile,
      "parentCategoryId":this.announcementForm.value.catagory==''?0:this.announcementForm.value.catagory,
      "subCategoryId":!this.isPresent?0:this.announcementForm.value.subcatogorycatagory,
      "vendorgroupId":this.announcementForm.value.groupId==''?0:this.announcementForm.value.groupId
    }
    console.log('Params',params);
    
    // return;
    let param =new FormData();
    param.append("title", this.announcementForm.value.title);
    param.append("content", this.announcementForm.value.html);
    console.log(this.selectedRoles);
    this.userRoles.forEach((role) => {
      param.append("userTypeId", role);  // Sends multiple roleId values
    });
    param.append("adminId", "1");
    param.append("shortDescription",this.announcementForm.value.shortDescription);
    param.append("sharePointLink",this.announcementForm.value.sharePointLink);
    param.append("shortTitle",this.announcementForm.value.shortTitle);
    param.append("announcementImage",this.selectedFile);
    param.append("parentCategoryId",this.announcementForm.value.catagory==''?0:this.announcementForm.value.catagory);
    if(this.isPresent){
    //  param.append("subCategoryId", this.announcementForm.value.subcatogorycatagory);
     if(this.allhideSub){
     this.AllSubcategoryMap.forEach((role)=>{
      param.append("subCategoryId",role);
     });
    }
    else{
      this.announcementForm.value.subcatogorycatagory.forEach((role)=>{
        param.append("subCategoryId",role);
       });
    }
    
    }
    console.log('Ann',)
    
      if( this.allhide){
     this.Allvendormap.forEach((role)=>{
      param.append("vendorgroupId",role);
     });
    }
    else{
      this.announcementForm.value.groupId.forEach((role)=>{
        param.append("vendorgroupId",role);
       });
    
    
    }
  //   if (!this.isPresent || this.announcementForm.value.subcatogorycatagory!='') {
  //     param.append("subCategoryId[]", ""); // Append an empty string for empty array
  // } else {
  //     this.announcementForm.value.subcatogorycatagory.forEach((id) => {
  //         param.append("subCategoryId[]", id); // Append each element
  //     });
  // } 

    // Append vendorgroupId
// if (!this.announcementForm.value.groupId || this.announcementForm.value.groupId === '') {
//   param.append("vendorgroupId[]", ""); // Append an empty value for an empty array
// } else {
//   this.announcementForm.value.groupId.forEach((id) => {
//       param.append("vendorgroupId[]", id); // Append each element of the array
//   });
// }
    console.log('param',param);
    
    param.forEach((value, key) => {
    console.log('key   ' + key + 'value  ' + value);
    });
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    
    this.spinnerService.show();

    if (this.announcementId == 0) {
      this.memberService
        .PostSendAnnouncement(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              $("#newAnnouncementCreate").modal("hide");
              this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: " Announcement Created Successfully",
                
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                this.ngOnInit()
              });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
    else {
      
      let subcategoryIdArray=[];
      if(this.announcementForm.value.subcatogorycatagory!=''){
      subcategoryIdArray.push(parseInt(this.announcementForm.value.subcatogorycatagory));
      }
      let vendorgroupIdArray=[];
     if( this.announcementForm.value.groupId!=''){
      vendorgroupIdArray.push(parseInt(this.announcementForm.value.groupId))
     }
      let param = {
        "title": this.titles,
        "content": this.content,
        "userTypeId": this.userRoles,
        "adminId": 1,
        "announcementId": this.announcementId,
        "shortDescription":this.shortDescription,
        "shortTitle":this.shortTitle,
        "announcementImage":this.selectedFile,
        "parentCategoryId":this.announcementForm.value.catagory,
        "subCategoryId":this.allhideSub?this.AllSubcategoryMap:this.announcementForm.value.subcatogorycatagory,
        "vendorgroupId":this.allhide?this.Allvendormap:this.announcementForm.value.groupId,
        "sharePointLink":this.shareLink
      }
      console.log('Param',param);
      // return;
      this.memberService
        .updateAnnouncement(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              $("#newAnnouncementCreate").modal("hide");
              this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Announcement sent to the selected group successfully",
                showCloseButton: true,
                allowOutsideClick: false,
                customClass: {
                  closeButton: 'my-close-button' // Assign custom class to close button
                }
              }).then(() => {
                this.ngOnInit()
              });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
  }
  getCatogoryList(){
    this.spinnerService.show();
    this.memberService
    .getCatogoryList( this.token)
    .subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          this.catagoryDropDown=data.categoryLists;
          this.catagoryDropDown=this.catagoryDropDown.map(value=>({
            categoryName:value.categoryName,
            categoryId:parseInt(value.categoryId),
          })
          );
         
        
          this.subCatagoryDropDowns = data.categoryLists.map((value=>value.subCategoryList));
          
          this.subCatagoryDropDowns = data.categoryLists
          .map(value => value.subCategoryList) // Extract subCategoryList arrays
          .flat() // Flatten the array of arrays into a single array
          .map(value => ({
            categoryName: value.categoryName,
            categoryId: parseInt(value.categoryId),
            parentCategoryId: parseInt(value.parentCategoryId)
          })); 
          
        
        console.log('usb',this.subCatagoryDropDowns)

          // this.groupList= data.vendorGroupList.map(group => ({
          //   id: group.groupId,
          //   name: group.name,
          // }));
         console.log('data -------> ',this.catagoryDropDown);
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }


} 

function foreach() {
  throw new Error('Function not implemented.');
}
